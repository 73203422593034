import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FaHandshake, FaClipboardList, FaCode, FaCheckCircle, FaRocket, FaTools, FaChartLine, FaComments } from 'react-icons/fa';
import { PageWrapper, fadeInUp, Container } from './GlobalStyles';
import Seo from './seo.js';

const PageTitle = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  animation: ${fadeInUp} 1s ease-out;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`;

const Section = styled.section`
  margin-bottom: 4rem;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #7efff5;
  margin-bottom: 1.5rem;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
`;

const ProcessSteps = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`;

const Step = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const StepIcon = styled.div`
  font-size: 3rem;
  color: #7efff5;
  margin-bottom: 1rem;
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #f0f0f0;
`;

const DetailModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeInUp} 0.3s ease-out;
`;

const ModalContent = styled.div`
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  padding: 2rem;
  border-radius: 15px;
  max-width: 600px;
  width: 90%;
  color: #ffffff;
  position: relative;
  animation: ${fadeInUp} 0.3s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
`;

const ModalTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #7efff5;
`;

const ModalDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
`;


const BenefitsSection = styled(Section)`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 2rem;
`;

const BenefitsList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
`;

const BenefitItem = styled.li`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const BenefitIcon = styled.span`
  font-size: 1.5rem;
  color: #7efff5;
  margin-right: 1rem;
`;

const BenefitText = styled.span`
  font-size: 1rem;
  color: #f0f0f0;
  line-height: 1.4;
`;

const WorkProcessVisualization = () => {
    const intl = useIntl();
    const [selectedStep, setSelectedStep] = useState(null);

    const steps = [
        {
            icon: <FaHandshake />,
            titleId: 'workProcess.consultation.title',
            descriptionId: 'workProcess.consultation.shortDescription',
            detailsId: 'workProcess.consultation.details',
            benefitsId: 'workProcess.consultation.benefits'
        },
        {
            icon: <FaClipboardList />,
            titleId: 'workProcess.planning.title',
            descriptionId: 'workProcess.planning.shortDescription',
            detailsId: 'workProcess.planning.details',
            benefitsId: 'workProcess.planning.benefits'
        },
        {
            icon: <FaCode />,
            titleId: 'workProcess.development.title',
            descriptionId: 'workProcess.development.shortDescription',
            detailsId: 'workProcess.development.details',
            benefitsId: 'workProcess.development.benefits'
        },
        {
            icon: <FaCheckCircle />,
            titleId: 'workProcess.testing.title',
            descriptionId: 'workProcess.testing.shortDescription',
            detailsId: 'workProcess.testing.details',
            benefitsId: 'workProcess.testing.benefits'
        },
        {
            icon: <FaRocket />,
            titleId: 'workProcess.deployment.title',
            descriptionId: 'workProcess.deployment.shortDescription',
            detailsId: 'workProcess.deployment.details',
            benefitsId: 'workProcess.deployment.benefits'
        },
        {
            icon: <FaTools />,
            titleId: 'workProcess.maintenance.title',
            descriptionId: 'workProcess.maintenance.shortDescription',
            detailsId: 'workProcess.maintenance.details',
            benefitsId: 'workProcess.maintenance.benefits'
        },
        {
            icon: <FaChartLine />,
            titleId: 'workProcess.optimization.title',
            descriptionId: 'workProcess.optimization.shortDescription',
            detailsId: 'workProcess.optimization.details',
            benefitsId: 'workProcess.optimization.benefits'
        },
        {
            icon: <FaComments />,
            titleId: 'workProcess.feedback.title',
            descriptionId: 'workProcess.feedback.shortDescription',
            detailsId: 'workProcess.feedback.details',
            benefitsId: 'workProcess.feedback.benefits'
        }
    ];

    const schema = {
        "@context": "https://schema.org",
        "@type": "Article",
        "name": intl.formatMessage({ id: 'workProcess.schemaName' }),
        "description": intl.formatMessage({ id: 'workProcess.schemaDescription' }),
        "url": "https://www.kodikolab.com/work-process"
    };

    return (
        <>
            <Seo
                title={intl.formatMessage({ id: 'workProcess.seoTitle' })}
                description={intl.formatMessage({ id: 'workProcess.seoDescription' })}
                keywords={intl.formatMessage({ id: 'workProcess.seoKeywords' })}
                canonicalUrl="https://www.kodikolab.com/work-process"
                schema={schema}
            />
        <PageWrapper>
            <Container>
                <PageTitle>{intl.formatMessage({ id: 'workProcess.title' })}</PageTitle>

                <Section delay={0.2}>
                    <SectionTitle>{intl.formatMessage({ id: 'workProcess.introduction.title' })}</SectionTitle>
                    <Paragraph>{intl.formatMessage({ id: 'workProcess.introduction.paragraph1' })}</Paragraph>
                    <Paragraph>{intl.formatMessage({ id: 'workProcess.introduction.paragraph2' })}</Paragraph>
                </Section>

                <Section delay={0.4}>
                    <SectionTitle>{intl.formatMessage({ id: 'workProcess.stepsOverview.title' })}</SectionTitle>
                    <Paragraph>{intl.formatMessage({ id: 'workProcess.stepsOverview.description' })}</Paragraph>
                    <ProcessSteps>
                        {steps.map((step) => (
                            <Step key={step.titleId} onClick={() => setSelectedStep(step.titleId)}>
                                <StepIcon>{step.icon}</StepIcon>
                                <StepTitle>{intl.formatMessage({ id: step.titleId })}</StepTitle>
                                <StepDescription>{intl.formatMessage({ id: step.descriptionId })}</StepDescription>
                            </Step>
                        ))}
                    </ProcessSteps>
                </Section>

                <BenefitsSection delay={0.6}>
                    <SectionTitle>{intl.formatMessage({ id: 'workProcess.benefits.title' })}</SectionTitle>
                    <Paragraph>{intl.formatMessage({ id: 'workProcess.benefits.description' })}</Paragraph>
                    <BenefitsList>
                        {intl.formatMessage({ id: 'workProcess.benefits.list' }).split(',').map((item) => (
                            <BenefitItem key={item.trim()}>
                                <BenefitIcon>✓</BenefitIcon>
                                <BenefitText>{item.trim()}</BenefitText>
                            </BenefitItem>
                        ))}
                    </BenefitsList>
                </BenefitsSection>

                {selectedStep !== null && (
                    <DetailModal onClick={() => setSelectedStep(null)}>
                        <ModalContent onClick={(e) => e.stopPropagation()}>
                            <CloseButton onClick={() => setSelectedStep(null)}>&times;</CloseButton>
                            <ModalTitle>{intl.formatMessage({ id: steps[selectedStep].titleId })}</ModalTitle>
                            <ModalDescription>
                                {intl.formatMessage({ id: steps[selectedStep].detailsId })}
                            </ModalDescription>
                            <BenefitsList>
                                {intl.formatMessage({ id: steps[selectedStep].benefitsId })
                                    .split(',')
                                    .map((benefit) => (
                                        <BenefitItem key={benefit.trim()}>{benefit.trim()}</BenefitItem>
                                    ))
                                }
                            </BenefitsList>
                        </ModalContent>
                    </DetailModal>
                )}
            </Container>
            </PageWrapper>
        </>
    );
};

export default WorkProcessVisualization;