import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageWrapper, Container, PageTitle } from './GlobalStyles';

const NotFoundWrapper = styled(PageWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const NotFoundContent = styled.div`
  text-align: center;
`;

const NotFoundMessage = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const HomeLink = styled(Link)`
  display: inline-block;
  background-color: #7efff5;
  color: #1e3c72;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffffff;
  }
`;

const NotFoundPage = () => {
    const intl = useIntl();

    return (
        <NotFoundWrapper>
            <Container>
                <NotFoundContent>
                    <PageTitle>{intl.formatMessage({ id: 'notFound.title' })}</PageTitle>
                    <NotFoundMessage>
                        {intl.formatMessage({ id: 'notFound.message' })}
                    </NotFoundMessage>
                    <HomeLink to="/">
                        {intl.formatMessage({ id: 'notFound.linkText' })}
                    </HomeLink>
                </NotFoundContent>
            </Container>
        </NotFoundWrapper>
    );
};

export default NotFoundPage;