import React from 'react';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import { FaCode, FaMobile, FaCloudUploadAlt, FaCog, FaStar, FaRocket } from 'react-icons/fa';
import { fadeInUp, gradientAnimation } from './GlobalStyles';

const floatAnimation = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(10deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const BannerWrapper = styled.div`
  background: linear-gradient(-45deg, #1e3c72, #2a5298, #4a00e0);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  color: #ffffff;
  padding: 150px 0 100px;
  position: relative;
  overflow: hidden;
`;

const FloatingShape = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: ${floatAnimation} 8s infinite ease-in-out;

  &:nth-child(1) { width: 120px; height: 120px; top: 10%; left: 10%; animation-delay: 0s; }
  &:nth-child(2) { width: 100px; height: 100px; top: 20%; right: 10%; animation-delay: 2s; }
  &:nth-child(3) { width: 80px; height: 80px; bottom: 10%; left: 20%; animation-delay: 4s; }
`;

const BannerContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const BannerText = styled.div`
  flex: 1;
`;

const BannerTitle = styled.h1`
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 800;
  margin-bottom: 1.5rem;
  animation: ${fadeInUp} 1s ease-out;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  color: #ffffff;
`;

const BannerSubtitle = styled.p`
  font-size: clamp(1.2rem, 3vw, 1.8rem);
  margin-bottom: 2.5rem;
  animation: ${fadeInUp} 1s ease-out 0.3s;
  animation-fill-mode: both;
  opacity: 0.95;
  line-height: 1.6;
  color: #f0f0f0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`;

const HighlightSpan = styled.span`
  color: #7efff5;
  font-weight: 700;
`;

const CTAButton = styled.button`
  background-color: #7efff5;
  color: #1e3c72;
  padding: 18px 50px;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: ${fadeInUp} 1s ease-out 0.6s;
  animation-fill-mode: both;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  margin-top: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover, &:focus {
    background-color: #ffffff;
    color: #4a00e0;
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    outline: none;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(126, 255, 245, 0.5), 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  svg {
    margin-left: 10px;
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin: 3rem 0;
  animation: ${fadeInUp} 1s ease-out 1.2s;
  animation-fill-mode: both;
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 1.5rem 1rem;
  border-radius: 20px;
  transition: all 0.4s ease;
  color: #ffffff;

  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  svg {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #7efff5;
  }
`;


const Stats = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 3rem;
  animation: ${fadeInUp} 1s ease-out 1.5s;
  animation-fill-mode: both;
`;

const StatItem = styled.div`
  text-align: center;
  margin: 1rem;

  .number {
    font-size: clamp(2rem, 4vw, 3rem);
    font-weight: bold;
    color: #7efff5;
  }

  .label {
    font-size: clamp(0.9rem, 2vw, 1.1rem);
    opacity: 0.95;
    margin-top: 0.5rem;
    color: #f0f0f0;
  }
`;
const FeatureText = styled.span`
  font-weight: 600;
  line-height: 1.4;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`;

const WaveTransition = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);

  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 70px;
  }

  .shape-fill {
    fill: #2a5298;
  }
`;

const DynamicBanner = () => {
  const intl = useIntl();
  const features = [
    { id: 'software', icon: <FaCode />, text: intl.formatMessage({ id: 'feature.software' }) },
    { id: 'mobile', icon: <FaMobile />, text: intl.formatMessage({ id: 'feature.mobile' }) },
    { id: 'cloud', icon: <FaCloudUploadAlt />, text: intl.formatMessage({ id: 'feature.cloud' }) },
    { id: 'automation', icon: <FaCog />, text: intl.formatMessage({ id: 'feature.automation' }) }
  ];

  const stats = [
    { id: 'clients', number: "150+", label: intl.formatMessage({ id: 'stats.clients' }) },
    { id: 'projects', number: "700+", label: intl.formatMessage({ id: 'stats.projects' }) },
    { id: 'satisfaction', number: <><FaStar /> 4.9</>, label: intl.formatMessage({ id: 'stats.satisfaction' }) }
  ];

  return (
    <BannerWrapper>
      {[1, 2, 3].map((num) => <FloatingShape key={`shape-${num}`} aria-hidden="true" />)}
      <BannerContent>
        <BannerText>
          <BannerTitle>
            {intl.formatMessage({ id: 'banner.title' }, { company: <HighlightSpan>Kodikolab</HighlightSpan> })}
          </BannerTitle>
          <BannerSubtitle>{intl.formatMessage({ id: 'banner.subtitle' })}</BannerSubtitle>
          <FeatureGrid>
            {features.map((feature) => (
              <FeatureItem key={feature.id}>
                <span aria-hidden="true">{feature.icon}</span>
                <FeatureText>{feature.text}</FeatureText>
              </FeatureItem>
            ))}
          </FeatureGrid>
          <Stats>
            {stats.map((stat) => (
              <StatItem key={stat.id}>
                <div className="number" aria-hidden="true">{stat.number}</div>
                <div className="label">{stat.label}</div>
              </StatItem>
            ))}
          </Stats>
          <CTAButton aria-label={intl.formatMessage({ id: 'banner.cta' })}>
            {intl.formatMessage({ id: 'banner.cta' })} <FaRocket aria-hidden="true" />
          </CTAButton>
        </BannerText>
      </BannerContent>
      <WaveTransition aria-hidden="true">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
      </WaveTransition>
    </BannerWrapper>
  );
};

export default DynamicBanner;