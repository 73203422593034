import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Sitemap = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/sitemap.xml') {
            const xmlContent = generateSitemap();
            const blob = new Blob([xmlContent], { type: 'application/xml' });
            const url = URL.createObjectURL(blob);
            window.location.replace(url);
        }
    }, [location]);

    const generateSitemap = () => {
        const hostname = 'https://main.devkodikolab.pl'; // Używaj rzeczywistego adresu
        const date = new Date().toISOString().split('T')[0];
        const routes = [
            { path: '/', priority: '1.0', changefreq: 'daily' },
            { path: '/projects', priority: '0.8', changefreq: 'weekly' },
            { path: '/services', priority: '0.8', changefreq: 'monthly' },
            { path: '/about', priority: '0.7', changefreq: 'monthly' },
            { path: '/contact', priority: '0.7', changefreq: 'monthly' },
            { path: '/team', priority: '0.6', changefreq: 'monthly' },
            { path: '/work-process', priority: '0.6', changefreq: 'monthly' },
            { path: '/project/itjuniors', priority: '0.7', changefreq: 'weekly' },
            { path: '/project/smartcity', priority: '0.7', changefreq: 'weekly' },
        ];

        return `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
${routes.map(route => `  <url>
    <loc>${hostname}${route.path}</loc>
    <lastmod>${date}</lastmod>
    <changefreq>${route.changefreq}</changefreq>
    <priority>${route.priority}</priority>
  </url>`).join('\n')}
</urlset>`;
    };

    return null;
};

export default Sitemap;