import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FaCode, FaLightbulb, FaHandshake, FaRocket } from 'react-icons/fa';
import Seo from './seo.js';

import {
    PageWrapper,
    Container,
    Section,
    PageTitle,
    SectionTitle,
    Paragraph,
    Card,
    Grid
} from './GlobalStyles';

const AboutPageTitle = styled(PageTitle)`
`;

const ValueGrid = styled(Grid)`
  margin-top: 3rem;
`;

const ValueCard = styled(Card)`
  text-align: center;
`;

const ValueIcon = styled.div`
  font-size: 3rem;
  color: #7efff5;
  margin-bottom: 1rem;
`;

const ValueTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
`;

const ValueDescription = styled.p`
  font-size: 1rem;
  color: #f0f0f0;
`;

const TeamSection = styled(Section)`
  text-align: center;
`;

const TeamDescription = styled(Paragraph)`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;


const AboutPage = () => {
    const intl = useIntl();
    const companyValues = [
        { icon: <FaCode />, titleId: 'about.values.codeExcellence.title', descriptionId: 'about.values.codeExcellence.description' },
        { icon: <FaLightbulb />, titleId: 'about.values.innovation.title', descriptionId: 'about.values.innovation.description' },
        { icon: <FaHandshake />, titleId: 'about.values.clientPartnership.title', descriptionId: 'about.values.clientPartnership.description' },
        { icon: <FaRocket />, titleId: 'about.values.continuousGrowth.title', descriptionId: 'about.values.continuousGrowth.description' }
    ];
    const schema = {
        "@context": "https://schema.org",
        "@type": "AboutPage",
        "name": intl.formatMessage({ id: 'about.schemaName' }),
        "description": intl.formatMessage({ id: 'about.schemaDescription' }),
        "url": "https://www.kodikolab.com/about"
    };

    return (
        <>
            <Seo
                title={intl.formatMessage({ id: 'about.seoTitle' })}
                description={intl.formatMessage({ id: 'about.seoDescription' })}
                keywords={intl.formatMessage({ id: 'about.seoKeywords' })}
                canonicalUrl="https://www.kodikolab.com/about"
                schema={schema}
            />
        <PageWrapper>
            <Container>
                <AboutPageTitle>{intl.formatMessage({ id: 'about.pageTitle' })}</AboutPageTitle>

                <Section delay={0.2}>
                    <SectionTitle>{intl.formatMessage({ id: 'about.story.title' })}</SectionTitle>
                    <Paragraph>{intl.formatMessage({ id: 'about.story.paragraph1' })}</Paragraph>
                    <Paragraph>{intl.formatMessage({ id: 'about.story.paragraph2' })}</Paragraph>
                </Section>

                <Section delay={0.4}>
                    <SectionTitle>{intl.formatMessage({ id: 'about.mission.title' })}</SectionTitle>
                    <Paragraph>{intl.formatMessage({ id: 'about.mission.paragraph1' })}</Paragraph>
                    <Paragraph>{intl.formatMessage({ id: 'about.mission.paragraph2' })}</Paragraph>
                </Section>

                <Section delay={0.6}>
                    <SectionTitle>{intl.formatMessage({ id: 'about.values.title' })}</SectionTitle>
                    <Paragraph>{intl.formatMessage({ id: 'about.values.description' })}</Paragraph>
                    <ValueGrid>
                        {companyValues.map((value) => (
                            <ValueCard key={value.titleId}>
                                <ValueIcon>{value.icon}</ValueIcon>
                                <ValueTitle>{intl.formatMessage({ id: value.titleId })}</ValueTitle>
                                <ValueDescription>{intl.formatMessage({ id: value.descriptionId })}</ValueDescription>
                            </ValueCard>
                        ))}
                    </ValueGrid>
                </Section>

                <TeamSection delay={0.8}>
                    <SectionTitle>{intl.formatMessage({ id: 'about.team.title' })}</SectionTitle>
                    <TeamDescription>{intl.formatMessage({ id: 'about.team.description1' })}</TeamDescription>
                    <TeamDescription>{intl.formatMessage({ id: 'about.team.description2' })}</TeamDescription>
                </TeamSection>

                <Section delay={1}>
                    <SectionTitle>{intl.formatMessage({ id: 'about.expertise.title' })}</SectionTitle>
                    <Paragraph>{intl.formatMessage({ id: 'about.expertise.introduction' })}</Paragraph>
                    <ul>
                        {intl.formatMessage({ id: 'about.expertise.list' }).split(',').map((item) => (
                            <li key={item.trim()}>{item.trim()}</li>
                        ))}
                    </ul>
                    <Paragraph>{intl.formatMessage({ id: 'about.expertise.conclusion' })}</Paragraph>
                </Section>

                <Section delay={1.2}>
                    <SectionTitle>{intl.formatMessage({ id: 'about.commitment.title' })}</SectionTitle>
                    <Paragraph>{intl.formatMessage({ id: 'about.commitment.paragraph1' })}</Paragraph>
                    <Paragraph>{intl.formatMessage({ id: 'about.commitment.paragraph2' })}</Paragraph>
                </Section>
            </Container>
            </PageWrapper>
        </>
    );
};

export default AboutPage;