import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Seo from './seo.js';
import { FaCode, FaReact, FaServer, FaMobile, FaDatabase, FaCloud, FaDocker, FaShieldAlt, FaTools, FaRocket, FaCog, FaUsersCog, FaChartLine, FaCheckCircle, FaArrowRight, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import {
    PageWrapper,
    Container,
    ParallaxSection,
    PageTitle,
    ServiceGrid,
    ServiceCard,
    ServiceIcon,
    ServiceTitle,
    ServiceDescription,
    SectionTitleAccent,
    ApproachDescription,
    BenefitsList,
    BenefitItem,
    ProcessSection,
    ProcessSteps,
    ProcessStep,
    ProcessStepNumber,
    ProcessStepTitle,
    CTASection,
    CTAButtons,
    CTAButton,
    CTASecondaryButton,
    FAQSection,
    FAQItem,
    FAQQuestion,
    FAQAnswer
} from './GlobalStyles';

const ServicesPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [openFAQ, setOpenFAQ] = useState(null);

    const schema = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": intl.formatMessage({ id: 'services.schemaName' }),
        "description": intl.formatMessage({ id: 'services.schemaDescription' }),
        "url": "https://www.kodikolab.com/services"
    };

    const services = [
        { icon: <FaCode />, titleId: 'services.customDevelopment.title', descriptionId: 'services.customDevelopment.description' },
        { icon: <FaReact />, titleId: 'services.frontendDevelopment.title', descriptionId: 'services.frontendDevelopment.description' },
        { icon: <FaServer />, titleId: 'services.backendDevelopment.title', descriptionId: 'services.backendDevelopment.description' },
        { icon: <FaMobile />, titleId: 'services.mobileDevelopment.title', descriptionId: 'services.mobileDevelopment.description' },
        { icon: <FaDatabase />, titleId: 'services.databaseDesign.title', descriptionId: 'services.databaseDesign.description' },
        { icon: <FaCloud />, titleId: 'services.cloudServices.title', descriptionId: 'services.cloudServices.description' },
        { icon: <FaDocker />, titleId: 'services.containerization.title', descriptionId: 'services.containerization.description' },
        { icon: <FaShieldAlt />, titleId: 'services.security.title', descriptionId: 'services.security.description' },
        { icon: <FaTools />, titleId: 'services.maintenance.title', descriptionId: 'services.maintenance.description' },
        { icon: <FaRocket />, titleId: 'services.performanceOptimization.title', descriptionId: 'services.performanceOptimization.description' },
        { icon: <FaCog />, titleId: 'services.devops.title', descriptionId: 'services.devops.description' },
        { icon: <FaUsersCog />, titleId: 'services.consultancy.title', descriptionId: 'services.consultancy.description' },
        { icon: <FaChartLine />, titleId: 'services.analytics.title', descriptionId: 'services.analytics.description' }
    ];

    const benefits = [
        { icon: <FaCheckCircle />, text: intl.formatMessage({ id: 'services.benefits.tailored' }) },
        { icon: <FaCheckCircle />, text: intl.formatMessage({ id: 'services.benefits.expertise' }) },
        { icon: <FaCheckCircle />, text: intl.formatMessage({ id: 'services.benefits.quality' }) },
        { icon: <FaCheckCircle />, text: intl.formatMessage({ id: 'services.benefits.support' }) },
        { icon: <FaCheckCircle />, text: intl.formatMessage({ id: 'services.benefits.innovation' }) },
        { icon: <FaCheckCircle />, text: intl.formatMessage({ id: 'services.benefits.costEffective' }) },
    ];

    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };

    const faqItems = [
        { questionId: 'services.faq1.question', answerId: 'services.faq1.answer' },
        { questionId: 'services.faq2.question', answerId: 'services.faq2.answer' },
        { questionId: 'services.faq3.question', answerId: 'services.faq3.answer' }
    ];

    return (
        <>
            <Seo
                title={intl.formatMessage({ id: 'services.seoTitle' })}
                description={intl.formatMessage({ id: 'services.seoDescription' })}
                keywords={intl.formatMessage({ id: 'services.seoKeywords' })}
                canonicalUrl="https://www.kodikolab.com/services"
                schema={schema}
            />
        <PageWrapper>
            <ParallaxSection style={{ backgroundImage: 'url(path_to_your_background_image.jpg)' }}>
                <PageTitle>{intl.formatMessage({ id: 'services.pageTitle' })}</PageTitle>
            </ParallaxSection>

            <Container>
                <SectionTitleAccent>{intl.formatMessage({ id: 'services.ourExpertise' })}</SectionTitleAccent>
                <ApproachDescription>
                    {intl.formatMessage({ id: 'services.expertiseDescription' })}
                </ApproachDescription>
                <ServiceGrid>
                    {services.map((service) => (
                        <ServiceCard key={service.titleId} delay={0.1 * (services.indexOf(service) + 1)}>
                            <ServiceIcon>{service.icon}</ServiceIcon>
                            <ServiceTitle>{intl.formatMessage({ id: service.titleId })}</ServiceTitle>
                            <ServiceDescription>{intl.formatMessage({ id: service.descriptionId })}</ServiceDescription>
                        </ServiceCard>
                    ))}
                </ServiceGrid>

                <ProcessSection>
                    <SectionTitleAccent>{intl.formatMessage({ id: 'services.ourProcess' })}</SectionTitleAccent>
                    <ApproachDescription>
                        {intl.formatMessage({ id: 'services.processDescription' })}
                    </ApproachDescription>
                    <ProcessSteps>
                        {[1, 2, 3, 4].map((step) => (
                            <ProcessStep key={step}>
                                <ProcessStepNumber>{step}</ProcessStepNumber>
                                <ProcessStepTitle>{intl.formatMessage({ id: `services.process.step${step}` })}</ProcessStepTitle>
                            </ProcessStep>
                        ))}
                    </ProcessSteps>
                </ProcessSection>

                <SectionTitleAccent>{intl.formatMessage({ id: 'services.individualApproach' })}</SectionTitleAccent>
                <ApproachDescription>
                    {intl.formatMessage({ id: 'services.approachDescription' })}
                </ApproachDescription>
                <BenefitsList>
                    {benefits.map((benefit) => (
                        <BenefitItem key={benefit.text}>
                            {benefit.icon} {benefit.text}
                        </BenefitItem>
                    ))}
                </BenefitsList>

                <FAQSection>
                    <SectionTitleAccent>{intl.formatMessage({ id: 'services.faq' })}</SectionTitleAccent>
                    {faqItems.map((item) => (
                        <FAQItem key={item.questionId}>
                            <FAQQuestion onClick={() => toggleFAQ(item.questionId)}>
                                {intl.formatMessage({ id: item.questionId })}
                                {openFAQ === item.questionId ? <FaChevronUp /> : <FaChevronDown />}
                            </FAQQuestion>
                            <FAQAnswer isOpen={openFAQ === item.questionId}>
                                {intl.formatMessage({ id: item.answerId })}
                            </FAQAnswer>
                        </FAQItem>
                    ))}
                </FAQSection>

                <CTASection>
                    <SectionTitleAccent>{intl.formatMessage({ id: 'services.readyToStart' })}</SectionTitleAccent>
                    <ApproachDescription>
                        {intl.formatMessage({ id: 'services.ctaDescription' })}
                    </ApproachDescription>
                    <CTAButtons>
                        <CTAButton onClick={() => navigate('/contact')}>
                            {intl.formatMessage({ id: 'services.contactUs' })} <FaArrowRight />
                        </CTAButton>
                        <CTASecondaryButton onClick={() => navigate('/portfolio')}>
                            {intl.formatMessage({ id: 'services.viewPortfolio' })}
                        </CTASecondaryButton>
                    </CTAButtons>
                </CTASection>
            </Container>
            </PageWrapper>
        </>
    );
};

export default ServicesPage;