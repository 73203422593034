import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { fadeInUp } from './GlobalStyles';

const FooterWrapper = styled.footer`
  background: linear-gradient(180deg, #2a5298, #1e3c72);
  color: #ffffff;
  padding: 100px 0 30px;
  position: relative;
  overflow: hidden;
`;

const WaveDivider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;

  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
  }

  .shape-fill {
    fill: #1e3c72;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const FooterSection = styled.div`
  flex: 1;
  margin-bottom: 40px;
  min-width: 250px;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;
`;

const FooterTitle = styled.h3`
  font-size: clamp(1.5rem, 3vw, 1.8rem);
  margin-bottom: 25px;
  color: #7efff5;
  position: relative;
 
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 50px;
    height: 3px;
    background-color: #7efff5;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 25px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 1.4rem;
  transition: all 0.3s ease;
  &:hover, &:focus {
    color: #7efff5;
    transform: translateY(-5px);
  }
`;

const FooterLinks = styled.ul`
  list-style: none;
  padding: 0;
`;

const FooterLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  display: block;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  font-size: clamp(1rem, 2vw, 1.1rem);
  &:hover, &:focus {
    color: #7efff5;
    transform: translateX(5px);
  }
`;

const ContactInfo = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: clamp(1rem, 2vw, 1.1rem);
  svg {
    margin-right: 15px;
    color: #7efff5;
    font-size: 1.3rem;
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  margin-top: 50px;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: clamp(0.9rem, 2vw, 1.1rem);
`;

const Footer = () => {
  const intl = useIntl();

  const socialLinks = [
    { id: 'facebook', icon: <FaFacebookF />, url: "#", label: intl.formatMessage({ id: 'footer.social.facebook' }) },
    { id: 'twitter', icon: <FaTwitter />, url: "#", label: intl.formatMessage({ id: 'footer.social.twitter' }) },
    { id: 'linkedin', icon: <FaLinkedinIn />, url: "#", label: intl.formatMessage({ id: 'footer.social.linkedin' }) },
    { id: 'instagram', icon: <FaInstagram />, url: "#", label: intl.formatMessage({ id: 'footer.social.instagram' }) }
  ];

  const quickLinks = [
    { id: 'home', to: "/", textId: 'footer.quickLinks.home' },
    { id: 'projects', to: "/projects", textId: 'footer.quickLinks.projects' },
    { id: 'about', to: "/about", textId: 'footer.quickLinks.about' },
    { id: 'contact', to: "/contact", textId: 'footer.quickLinks.contact' }
  ];

  const contactInfo = [
    { id: 'email', icon: <FaEnvelope />, textId: 'footer.contact.email' },
    { id: 'phone', icon: <FaPhone />, textId: 'footer.contact.phone' },
    { id: 'address', icon: <FaMapMarkerAlt />, textId: 'footer.contact.address' }
  ];

  return (
    <FooterWrapper>
      <WaveDivider>
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
      </WaveDivider>
      <FooterContent>
        <FooterSection delay={0.2}>
          <FooterTitle>{intl.formatMessage({ id: 'footer.company' })}</FooterTitle>
          <p>{intl.formatMessage({ id: 'footer.slogan' })}</p>
          <SocialIcons>
            {socialLinks.map((link) => (
              <SocialIcon key={link.id} href={link.url} aria-label={link.label}>
                {link.icon}
              </SocialIcon>
            ))}
          </SocialIcons>
        </FooterSection>
        <FooterSection delay={0.4}>
          <FooterTitle>{intl.formatMessage({ id: 'footer.quickLinks.title' })}</FooterTitle>
          <FooterLinks>
            {quickLinks.map((link) => (
              <li key={link.id}><FooterLink to={link.to}>{intl.formatMessage({ id: link.textId })}</FooterLink></li>
            ))}
          </FooterLinks>
        </FooterSection>
        <FooterSection delay={0.6}>
          <FooterTitle>{intl.formatMessage({ id: 'footer.contact.title' })}</FooterTitle>
          {contactInfo.map((info) => (
            <ContactInfo key={info.id}>{info.icon} {intl.formatMessage({ id: info.textId })}</ContactInfo>
          ))}
        </FooterSection>
      </FooterContent>
      <FooterBottom>
        <p>{intl.formatMessage({ id: 'footer.copyright' }, { year: new Date().getFullYear() })}</p>
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;