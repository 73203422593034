import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FaArrowRight, FaSpinner, FaGraduationCap } from 'react-icons/fa';
import { fadeInUp } from './GlobalStyles';

const Section = styled.section`
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  color: #ffffff;
  padding: 100px 0;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 3.5rem;
  margin-bottom: 3rem;
  animation: ${fadeInUp} 1s ease-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background-color: #7efff5;
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2.5rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const ProjectCard = styled(Link)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.4s ease;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translateY(-10px) scale(1.03);
    background-color: rgba(255, 255, 255, 0.15);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

const ProjectImageWrapper = styled.div`
  position: relative;
  height: 220px;
  overflow: hidden;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;

  ${ProjectCard}:hover & {
    transform: scale(1.1);
  }
`;

const ProjectImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(30, 60, 114, 0.2), rgba(30, 60, 114, 0.8));
  display: flex;
  align-items: flex-end;
  padding: 1.5rem;
`;

const ProjectTitle = styled.h3`
  font-size: 1.8rem;
  color: #ffffff;
  margin: 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
`;

const ProjectContent = styled.div`
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  opacity: 0.95;
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #e6e6e6;
  flex-grow: 1;
`;

const ProjectDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #7efff5;
  margin-top: auto;
`;

const ViewDetailsButton = styled.div`
  background-color: #7efff5;
  color: #1e3c72;
  padding: 0.7rem 1.2rem;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1.5rem;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ffffff;
    color: #4a00e0;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(126, 255, 245, 0.4);
  }

  svg {
    margin-left: 8px;
  }
`;

const ViewAllButton = styled(Link)`
  background-color: transparent;
  color: #7efff5;
  padding: 15px 40px;
  font-size: 1.2rem;
  font-weight: 700;
  border: 2px solid #7efff5;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 4rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover, &:focus {
    background-color: #7efff5;
    color: #1e3c72;
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(126, 255, 245, 0.3);
    outline: none;
  }

  svg {
    margin-left: 10px;
  }
`;

const LoadingSpinner = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  font-size: 3rem;
  margin: 3rem 0;
  color: #7efff5;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.p`
  color: #ff6b6b;
  font-size: 1.2rem;
  margin: 2rem 0;
  background-color: rgba(255, 107, 107, 0.1);
  padding: 1rem 2rem;
  border-radius: 10px;
  display: inline-block;
`;

const RecentProjects = () => {
    const intl = useIntl();
    const [recentProjects, setRecentProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchRecentProjects = useCallback(async () => {
        try {
            setIsLoading(true);
            setError(null);
            await new Promise(resolve => setTimeout(resolve, 100));

            const sampleProjects = [
                {
                    id: 'itjuniors',
                    name: 'ITJuniors',
                    description: 'Innovative educational platform for aspiring programmers, featuring interactive coding lessons and personalized learning paths.',
                    imageUrl: 'https://images.unsplash.com/photo-1516116216624-53e697fedbea?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
                    status: intl.formatMessage({ id: 'projects.status.inProgress' }),
                    clientName: 'ITJuniors',
                    icon: <FaGraduationCap />
                },
            ];

            setRecentProjects(sampleProjects);
        } catch (error) {
            console.error(intl.formatMessage({ id: 'projects.fetchError' }), error);
            setError(intl.formatMessage({ id: 'projects.loadError' }));
        } finally {
            setIsLoading(false);
        }
    }, [intl]); 

    useEffect(() => {
        fetchRecentProjects();
    }, [fetchRecentProjects]);

    const renderContent = () => {
        if (isLoading) {
            return <LoadingSpinner aria-label={intl.formatMessage({ id: 'projects.loading' })} />;
        }

        if (error) {
            return <ErrorMessage>{error}</ErrorMessage>;
        }

        return (
            <>
                <ProjectGrid>
                    {recentProjects.map((project) => (
                        <ProjectCard to={`/project/${project.id}`} key={project.id} delay={0.2 * (recentProjects.indexOf(project) + 1)}>
                            <ProjectImageWrapper>
                                <ProjectImage src={project.imageUrl} alt={`${project.name} ${intl.formatMessage({ id: 'projects.imageAlt' })}`} />
                                <ProjectImageOverlay>
                                    <ProjectTitle>{project.name}</ProjectTitle>
                                </ProjectImageOverlay>
                            </ProjectImageWrapper>
                            <ProjectContent>
                                <ProjectDescription>{project.description}</ProjectDescription>
                                <ProjectDetails>
                                    <span>{intl.formatMessage({ id: 'projects.status' })}: {project.status}</span>
                                    <span>{intl.formatMessage({ id: 'projects.client' })}: {project.clientName}</span>
                                </ProjectDetails>
                                <ViewDetailsButton>
                                    {intl.formatMessage({ id: 'projects.viewDetails' })} <FaArrowRight />
                                </ViewDetailsButton>
                            </ProjectContent>
                        </ProjectCard>
                    ))}
                </ProjectGrid>
                <ViewAllButton to="/projects">
                    {intl.formatMessage({ id: 'projects.viewAll' })} <FaArrowRight aria-hidden="true" />
                </ViewAllButton>
            </>
        );
    };

    return (
        <Section>
            <SectionTitle>{intl.formatMessage({ id: 'projects.sectionTitle' })}</SectionTitle>
            {renderContent()}
        </Section>
    );
};

export default RecentProjects;