import React, { useState, useEffect } from 'react';
import DynamicBanner from './DynamicBanner';
import WhyKodikolab from './WhyKodikolab';
import ProjectCounter from './ProjectCounter';
import { useIntl } from 'react-intl';
import RecentProjects from './RecentProjects';
import NewsEvents from './NewsEvents';
import Seo from './seo'

const HomePage = () => {
    const intl = useIntl();
  const [projectStats, setProjectStats] = useState(null);

  useEffect(() => {
    fetchProjectStats();
  }, []);

  const fetchProjectStats = async () => {
    try {
      const response = await fetch('/api/projects/statistics');
      const data = await response.json();
      setProjectStats(data);
    } catch (error) {
      console.error('Error fetching project statistics:', error);
    }
    };
    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Kodikolab",
        "description": intl.formatMessage({ id: 'home.schemaDescription' }),
        "url": "https://www.kodikolab.com",
        "logo": "https://www.kodikolab.com/logo.png"
    };

  return (
    <>
      <Seo 
        title={intl.formatMessage({ id: 'home.seoTitle' })}
        description={intl.formatMessage({ id: 'home.seoDescription' })}
        keywords={intl.formatMessage({ id: 'home.seoKeywords' })}
        canonicalUrl="https://www.kodikolab.com"
        schema={schema}
      />
    <div className="home-page">
      <DynamicBanner />
      <WhyKodikolab />
      {projectStats && <ProjectCounter stats={projectStats} />}
      <RecentProjects />
      <NewsEvents />
    </div>
    </>
  );
};

export default HomePage;