import React from 'react';
import PropTypes from 'prop-types';

const ProjectCounter = ({ stats }) => {
  return (
    <section className="project-counter">
      <div>
        <h3>Total Projects</h3>
        <span>{stats.totalProjects}</span>
      </div>
      <div>
        <h3>Completed Projects</h3>
        <span>{stats.completedProjects}</span>
      </div>
      <div>
        <h3>Average Budget</h3>
        <span>${stats.averageBudget.toFixed(2)}k</span>
      </div>
      <div>
        <h3>Avg. Project Duration</h3>
        <span>{stats.averageProjectDuration.toFixed(0)} days</span>
      </div>
    </section>
  );
};

ProjectCounter.propTypes = {
  stats: PropTypes.shape({
    totalProjects: PropTypes.number.isRequired,
    completedProjects: PropTypes.number.isRequired,
    averageBudget: PropTypes.number.isRequired,
    averageProjectDuration: PropTypes.number.isRequired,
  }).isRequired,
};

export default ProjectCounter;