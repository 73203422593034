import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import HomePage from './components/HomePage';
import ProjectsPage from './components/ProjectsPage';
import ProjectDetailsPage from './components/ProjectDetailsPage';
import ServicesPage from './components/ServicesPage';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import TeamPage from './components/TeamPage';
import WorkProcessVisualization from './components/WorkProcessVisualization';
import NotFoundPage from './components/NotFoundPage'; // Dodaj ten import
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Sitemap from './components/Sitemap';
import { LOCALES } from './i18n/locales';
import { messages } from './i18n/messages';
import './App.css';

function App() {
  const [currentLocale, setCurrentLocale] = useState(LOCALES.ENGLISH);

  return (
    <HelmetProvider>
      <IntlProvider messages={messages[currentLocale]} locale={currentLocale}>
        <Router>
          <ScrollToTop />
          <Sitemap />
          <div className="App">
            <Header 
              setCurrentLocale={setCurrentLocale} 
              currentLocale={currentLocale} 
            />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/projects" element={<ProjectsPage />} />
              <Route path="/project/:id" element={<ProjectDetailsPage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/team" element={<TeamPage />} />
              <Route path="/work-process" element={<WorkProcessVisualization />} />
              <Route path="/sitemap.xml" element={<Sitemap />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </IntlProvider>
    </HelmetProvider>
  );
}

export default App;