import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FaCode, FaRocket, FaServer, FaGraduationCap, FaLaptopCode, FaUserGraduate, FaMobileAlt, FaLock } from 'react-icons/fa';
import { PageWrapper, fadeInUp, Container, Section } from './GlobalStyles';
import Seo from './seo.js';

const ProjectTitle = styled.h1`
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 1rem;
  animation: ${fadeInUp} 1s ease-out;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`;

const ProjectSubtitle = styled.h2`
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 3rem;
  color: #7efff5;
  animation: ${fadeInUp} 1s ease-out 0.2s;
  animation-fill-mode: both;
`;

const ProjectDescription = styled.p`
  font-size: 1.2rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;
  line-height: 1.6;
  animation: ${fadeInUp} 1s ease-out 0.4s;
  animation-fill-mode: both;
`;

const SectionTitle = styled.h2`
  font-size: 2.2rem;
  color: #7efff5;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Timeline = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
  &::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #7efff5;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
`;

const TimelineItem = styled.div`
  padding: 10px 40px;
  position: relative;
  width: 50%;
  box-sizing: border-box;
  &::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: ${props => props.completed ? '#7efff5' : 'rgba(255, 255, 255, 0.3)'};
    border: 4px solid #FF9F55;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  &:nth-child(even) {
    left: 50%;
    &::after {
      left: -16px;
    }
  }
  &:nth-child(odd) {
    left: 0;
  }
`;

const TimelineContent = styled.div`
  padding: 20px 30px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  border-radius: 6px;
  &::before {
    content: '';
    position: absolute;
    top: 15px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
  }
  ${TimelineItem}:nth-child(odd) & {
    &::before {
      right: -14px;
      border-left: 14px solid rgba(255, 255, 255, 0.1);
    }
  }
  ${TimelineItem}:nth-child(even) & {
    &::before {
      left: -14px;
      border-right: 14px solid rgba(255, 255, 255, 0.1);
    }
  }
`;

const TimelineTitle = styled.h3`
  font-size: 1.4rem;
  color: #7efff5;
  margin-top: 0;
`;

const TimelineDescription = styled.p`
  font-size: 1rem;
  color: #f0f0f0;
`;

const TimelineDate = styled.span`
  display: block;
  font-size: 0.9rem;
  color: #f0f0f0;
  opacity: 0.8;
  margin-top: 10px;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FeatureItem = styled.li`
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.2);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: #7efff5;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #ffffff;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #f0f0f0;
`;

const TechStack = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

const TechItem = styled.span`
  background: rgba(255, 255, 255, 0.1);
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-3px);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  background: #7efff5;
  color: #1e3c72;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  margin-top: 2rem;

  &:hover {
    background: #ffffff;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

const ProjectDetailsPage = () => {
    const { id } = useParams();
    const intl = useIntl();
    const [project, setProject] = useState(null);

    useEffect(() => {
        const projectsData = {
            itjuniors: {
                title: 'project.itjuniors.title',
                subtitle: 'project.itjuniors.subtitle',
                description: 'project.itjuniors.description',
                features: [
                    {
                        icon: <FaGraduationCap />,
                        title: 'project.itjuniors.features.personalized.title',
                        description: 'project.itjuniors.features.personalized.description'
                    },
                    {
                        icon: <FaLaptopCode />,
                        title: 'project.itjuniors.features.interactive.title',
                        description: 'project.itjuniors.features.interactive.description'
                    },
                    {
                        icon: <FaUserGraduate />,
                        title: 'project.itjuniors.features.mentoring.title',
                        description: 'project.itjuniors.features.mentoring.description'
                    },
                    {
                        icon: <FaMobileAlt />,
                        title: 'project.itjuniors.features.mobile.title',
                        description: 'project.itjuniors.features.mobile.description'
                    },
                    {
                        icon: <FaLock />,
                        title: 'project.itjuniors.features.security.title',
                        description: 'project.itjuniors.features.security.description'
                    }
                ],
                technologies: [
                    { icon: <FaCode />, name: 'project.itjuniors.technologies.dotnet' },
                    { icon: <FaCode />, name: 'project.itjuniors.technologies.react' },
                    { icon: <FaServer />, name: 'project.itjuniors.technologies.sqlserver' },
                    { icon: <FaRocket />, name: 'project.itjuniors.technologies.azure' }
                ],
                timeline: [
                    {
                        title: 'project.itjuniors.timeline.concept.title',
                        description: 'project.itjuniors.timeline.concept.description',
                        date: 'project.itjuniors.timeline.concept.date',
                        completed: true
                    },
                    {
                        title: 'project.itjuniors.timeline.uxui.title',
                        description: 'project.itjuniors.timeline.uxui.description',
                        date: 'project.itjuniors.timeline.uxui.date',
                        completed: true
                    },
                    {
                        title: 'project.itjuniors.timeline.backend.title',
                        description: 'project.itjuniors.timeline.backend.description',
                        date: 'project.itjuniors.timeline.backend.date',
                        completed: true
                    },
                    {
                        title: 'project.itjuniors.timeline.frontend.title',
                        description: 'project.itjuniors.timeline.frontend.description',
                        date: 'project.itjuniors.timeline.frontend.date',
                        completed: true
                    },
                    {
                        title: 'project.itjuniors.timeline.testing.title',
                        description: 'project.itjuniors.timeline.testing.description',
                        date: 'project.itjuniors.timeline.testing.date',
                        completed: false
                    },
                    {
                        title: 'project.itjuniors.timeline.softlaunch.title',
                        description: 'project.itjuniors.timeline.softlaunch.description',
                        date: 'project.itjuniors.timeline.softlaunch.date',
                        completed: false
                    },
                    {
                        title: 'project.itjuniors.timeline.launch.title',
                        description: 'project.itjuniors.timeline.launch.description',
                        date: 'project.itjuniors.timeline.launch.date',
                        completed: false
                    }
                ],
                cta: {
                    title: 'project.itjuniors.cta.title',
                    description: 'project.itjuniors.cta.description',
                    button: 'project.itjuniors.cta.button'
                }
            },
            smartcity: {
                title: 'project.smartcity.title',
                subtitle: 'project.smartcity.subtitle',
                description: 'project.smartcity.description',
                features: [
                    {
                        icon: <FaMobileAlt />,
                        title: 'project.smartcity.features.mobile.title',
                        description: 'project.smartcity.features.mobile.description'
                    }
                ],
                technologies: [
                    { icon: <FaCode />, name: 'project.smartcity.technologies.react' },
                    { icon: <FaServer />, name: 'project.smartcity.technologies.nodejs' }
                ]
            }
        };

        setProject(projectsData[id] || projectsData.itjuniors);
    }, [id]);

    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <PageWrapper>
            <Seo
                title={intl.formatMessage({ id: `project.${id}.seo.title` })}
                description={intl.formatMessage({ id: `project.${id}.seo.description` })}
                keywords={intl.formatMessage({ id: `project.${id}.seo.keywords` })}
                canonicalUrl={`https://www.kodikolab.com/projects/${id}`}
                schema={{
                    "@context": "https://schema.org",
                    "@type": "Project",
                    "name": intl.formatMessage({ id: project.title }),
                    "description": intl.formatMessage({ id: project.description }),
                    "url": `https://www.kodikolab.com/projects/${id}`
                }}
            />
            <Container>
                <ProjectTitle>{intl.formatMessage({ id: project.title })}</ProjectTitle>
                <ProjectSubtitle>{intl.formatMessage({ id: project.subtitle })}</ProjectSubtitle>
                <ProjectDescription>
                    {intl.formatMessage({ id: project.description })}
                </ProjectDescription>

                <Section delay="0.2s">
                    <SectionTitle>{intl.formatMessage({ id: 'project.features.title' })}</SectionTitle>
                    <FeatureList>
                        {project.features.map((feature) => (
                            <FeatureItem key={feature.title}>
                                <FeatureIcon>{feature.icon}</FeatureIcon>
                                <FeatureTitle>{intl.formatMessage({ id: feature.title })}</FeatureTitle>
                                <FeatureDescription>{intl.formatMessage({ id: feature.description })}</FeatureDescription>
                            </FeatureItem>
                        ))}
                    </FeatureList>
                </Section>

                <Section delay="0.4s">
                    <SectionTitle>{intl.formatMessage({ id: 'project.technologies.title' })}</SectionTitle>
                    <TechStack>
                        {project.technologies.map((tech) => (
                            <TechItem key={tech.name}>{tech.icon} {intl.formatMessage({ id: tech.name })}</TechItem>
                        ))}
                    </TechStack>
                </Section>

                {project.timeline && (
                    <Section delay="0.6s">
                        <SectionTitle>{intl.formatMessage({ id: 'project.timeline.title' })}</SectionTitle>
                        <Timeline>
                            {project.timeline.map((item) => (
                                <TimelineItem key={item.title} completed={item.completed}>
                                    <TimelineContent>
                                        <TimelineTitle>{intl.formatMessage({ id: item.title })}</TimelineTitle>
                                        <TimelineDescription>{intl.formatMessage({ id: item.description })}</TimelineDescription>
                                        <TimelineDate>{intl.formatMessage({ id: item.date })}</TimelineDate>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </Section>
                )}

                {project.cta && (
                    <Section delay="0.8s" style={{ textAlign: 'center' }}>
                        <SectionTitle>{intl.formatMessage({ id: project.cta.title })}</SectionTitle>
                        <ProjectDescription>
                            {intl.formatMessage({ id: project.cta.description })}
                        </ProjectDescription>
                        <CTAButton href="/contact">{intl.formatMessage({ id: project.cta.button })}</CTAButton>
                    </Section>
                )}
            </Container>
        </PageWrapper>
    );
};

export default ProjectDetailsPage;