import styled, { keyframes, css } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

export const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(30px); }
  to { opacity: 1; transform: translateY(0); }
`;

export const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`;

export const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

export const colors = {
    primary: '#1e3c72',
    secondary: '#2a5298',
    accent: '#7efff5',
    white: '#ffffff',
    lightGray: '#f0f0f0',
    darkGray: '#333333',
    black: '#000000',
};

export const PageWrapper = styled.div`
  background: linear-gradient(180deg, ${colors.secondary}, ${colors.primary});
  color: ${colors.white};
  padding: 80px 0;
  min-height: 100vh;
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const Section = styled.section`
  margin-bottom: 4rem;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay || '0s'};
`;

export const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: ${colors.accent};
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const PageTitle = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  animation: ${fadeInUp} 1s ease-out;
  color: ${colors.white};
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
`;

export const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: ${colors.lightGray};
`;

export const Button = styled.button`
  padding: 12px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const PrimaryButton = styled(Button)`
  background-color: ${colors.accent};
  color: ${colors.primary};

  &:hover {
    background-color: ${colors.white};
    color: ${colors.secondary};
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: transparent;
  border: 2px solid ${colors.accent};
  color: ${colors.accent};

  &:hover {
    background-color: ${colors.accent};
    color: ${colors.primary};
  }
`;

export const Card = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 2rem;
  transition: all 0.4s ease;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay || '0s'};

  &:hover {
    transform: translateY(-10px) scale(1.03);
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

export const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${colors.white};
`;

export const CardDescription = styled.p`
  font-size: 1rem;
  opacity: 0.95;
  color: ${colors.lightGray};
  line-height: 1.6;
`;

export const CardIcon = styled.div`
  font-size: 3rem;
  color: ${colors.accent};
  margin-bottom: 1rem;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  &:before {
    content: "✓";
    color: ${colors.accent};
    font-weight: bold;
    margin-right: 0.5rem;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: ${colors.white};
  margin-bottom: 1rem;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colors.accent};
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  color: ${colors.white};
  margin-bottom: 1rem;
  resize: vertical;
  min-height: 150px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colors.accent};
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: ${colors.accent};
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeInUp} 0.3s ease-out;
`;

export const ModalContent = styled.div`
  background: linear-gradient(135deg, ${colors.primary}, ${colors.secondary});
  padding: 2rem;
  border-radius: 15px;
  max-width: 600px;
  width: 90%;
  color: ${colors.white};
  position: relative;
  animation: ${fadeInUp} 0.3s ease-out;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: ${colors.white};
  font-size: 1.5rem;
  cursor: pointer;
`;

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ParallaxSection = styled.div`
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const backgroundGradient = css`
  background: linear-gradient(135deg, ${colors.primary}, ${colors.secondary});
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
`;

export const hoverEffect = css`
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;
export const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

export const ServiceCard = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2.5rem;
  transition: all 0.4s ease;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-15px) scale(1.03);
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  }
`;

export const ServiceIcon = styled.div`
  font-size: 3.5rem;
  color: ${colors.accent};
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;

  ${ServiceCard}:hover & {
    transform: scale(1.2);
  }
`;

export const ServiceTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${colors.white};
`;

export const ServiceDescription = styled.p`
  font-size: 1rem;
  opacity: 0.95;
  color: ${colors.lightGray};
  line-height: 1.6;
`;

export const SectionTitleAccent = styled.h2`
  font-size: 2.8rem;
  color: ${colors.accent};
  text-align: center;
  margin-bottom: 2.5rem;
  position: relative;
  display: inline-block;
  padding: 0 20px;
  animation: ${fadeInUp} 1s ease-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 60%;
    height: 4px;
    background-color: ${colors.accent};
    transform: translateX(-50%);
  }
`;

export const ApproachDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2rem;
  text-align: center;
  animation: ${fadeInUp} 1s ease-out;
`;

export const BenefitsList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  animation: ${fadeInUp} 1s ease-out;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

export const BenefitItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.1rem;

  svg {
    color: ${colors.accent};
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`;

export const ProcessSection = styled.section`
  margin-top: 6rem;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 4rem 2rem;
  animation: ${fadeInUp} 1s ease-out;
  border-radius: 30px;
`;

export const ProcessSteps = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 3rem;
`;

export const ProcessStep = styled.div`
  text-align: center;
  flex: 1;
  min-width: 200px;
  margin: 1rem;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const ProcessStepNumber = styled.div`
  background-color: ${colors.accent};
  color: ${colors.primary};
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0 auto 1.5rem;
`;

export const ProcessStepTitle = styled.h4`
  font-size: 1.2rem;
  color: ${colors.white};
`;

export const CTASection = styled.div`
  text-align: center;
  margin-top: 6rem;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4rem 2rem;
  animation: ${fadeInUp} 1s ease-out;
  border-radius: 30px;
`;

export const CTAButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

export const CTAButton = styled.button`
  background-color: ${colors.accent};
  color: ${colors.primary};
  padding: 1rem 2.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${colors.white};
    color: ${colors.secondary};
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(126, 255, 245, 0.4);
  }

  svg {
    margin-left: 10px;
  }
`;

export const CTASecondaryButton = styled(CTAButton)`
  background-color: transparent;
  border: 2px solid ${colors.accent};
  color: ${colors.accent};

  &:hover {
    background-color: ${colors.accent};
    color: ${colors.primary};
  }
`;

export const FAQSection = styled.section`
  margin-top: 6rem;
  animation: ${fadeInUp} 1s ease-out;
`;

export const FAQItem = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export const FAQQuestion = styled.button`
  font-size: 1.2rem;
  color: ${colors.accent};
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 0;

  svg {
    transition: transform 0.3s ease;
  }
`;

export const FAQAnswer = styled.div`
  font-size: 1rem;
  color: ${colors.lightGray};
  margin-top: 1rem;
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;
export const PageSubtitle = styled.p`
  font-size: 1.2rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;
  color: ${colors.accent};
  animation: ${fadeInUp} 1s ease-out 0.2s;
  animation-fill-mode: both;
`;

export const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
`;

export const TeamMember = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  transition: all 0.4s ease;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;

  &:hover {
    transform: translateY(-10px) scale(1.05);
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

export const MemberIcon = styled.div`
  font-size: 3rem;
  color: ${colors.accent};
  margin-bottom: 1rem;
  animation: ${float} 3s ease-in-out infinite;
`;

export const MemberRole = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.white};
`;

export const MemberDescription = styled.p`
  font-size: 1rem;
  color: ${colors.lightGray};
  line-height: 1.6;
  margin-bottom: 1rem;
`;

export const TeamStrengthSection = styled.section`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4rem 0;
  margin-top: 4rem;
  animation: ${fadeInUp} 1s ease-out;
`;

export const StrengthList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  padding: 0;
  list-style-type: none;
  max-width: 1000px;
  margin: 0 auto;
`;

export const StrengthItem = styled.li`
  text-align: center;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;
`;

export const StrengthIcon = styled.div`
  font-size: 2.5rem;
  color: ${colors.accent};
  margin-bottom: 1rem;
`;

export const StrengthTitle = styled.h4`
  font-size: 1.2rem;
  color: ${colors.white};
  margin-bottom: 0.5rem;
`;

export const StrengthDescription = styled.p`
  font-size: 0.9rem;
  color: ${colors.lightGray};
`;
export const CTAButtonAnimated = styled(CTAButton)`
  display: block;
  margin: 4rem auto 0;
  animation: ${fadeInUp} 1s ease-out 1s;
  animation-fill-mode: both;
`;
export const ProjectsSectionTitle = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 3rem;
  animation: ${fadeInUp} 1s ease-out, ${float} 3s ease-in-out infinite;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background-color: #7efff5;
  }
`;

export const SearchFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  animation: ${fadeInUp} 1s ease-out 0.2s;
  animation-fill-mode: both;
`;

export const ProjectImageWrapper = styled.div`
  position: relative;
  height: 220px;
  overflow: hidden;
`;

export const ProjectImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(30, 60, 114, 0.2), rgba(30, 60, 114, 0.8));
  display: flex;
  align-items: flex-end;
  padding: 1.5rem;
`;

export const SearchInput = styled.input`
  flex: 1;
  padding: 15px 25px;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  margin-right: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  transition: all 0.3s ease;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  &:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 15px rgba(126, 255, 245, 0.5);
  }
`;

export const FilterButton = styled.button`
  background-color: #7efff5;
  color: #1e3c72;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #ffffff;
    color: #4a00e0;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  svg {
    margin-right: 10px;
  }
`;

export const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2.5rem;
  margin-bottom: 4rem;
`;

export const ProjectCard = styled(Link)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.4s ease;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;
  text-decoration: none;
  color: inherit;
  position: relative;

  &:hover {
    transform: translateY(-10px) scale(1.03);
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

export const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.4s ease;

  ${ProjectCard}:hover & {
    transform: scale(1.1);
  }
`;

export const ProjectContent = styled.div`
  padding: 1.5rem;
`;

export const ProjectTitle = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #7efff5;
`;

export const ProjectDescription = styled.p`
  font-size: 1rem;
  opacity: 0.95;
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #ffffff;
`;

export const ProjectDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #f0f0f0;
`;

export const ViewDetailsButton = styled.div`
  background-color: #7efff5;
  color: #1e3c72;
  padding: 0.7rem 1.2rem;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1.5rem;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ffffff;
    color: #4a00e0;
    transform: translateX(5px);
  }

  svg {
    margin-left: 8px;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translateX(5px);
  }
`;

export const LoadingSpinner = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  font-size: 3rem;
  margin: 2rem auto;
  display: block;
  color: #7efff5;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const ErrorMessage = styled.p`
  color: #ff6b6b;
  font-size: 1.2rem;
  text-align: center;
  margin: 2rem 0;
  background-color: rgba(255, 107, 107, 0.1);
  padding: 1rem;
  border-radius: 10px;
`;

export const FilterContainer = styled.div`
  display: flex;
  animation: ${fadeInUp} 1s ease-out;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const FilterPill = styled.button`
  background-color: ${props => props.active ? '#7efff5' : 'rgba(255, 255, 255, 0.1)'};
  color: ${props => props.active ? '#1e3c72' : '#ffffff'};
  border: none;
  border-radius: 50px;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  font-weight: ${props => props.active ? 'bold' : 'normal'};

  &:hover {
    background-color: #7efff5;
    color: #1e3c72;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(126, 255, 245, 0.4);
  }
`;

export const ProjectIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 10px;
  font-size: 1.5rem;
  color: #7efff5;
`;
