import React from 'react';
import { useIntl } from 'react-intl';
import { FaCode, FaBug, FaServer, FaPalette, FaChartLine, FaLightbulb, FaUsers, FaRocket } from 'react-icons/fa';
import Seo from './seo.js';
import {
    PageWrapper,
    Container,
    PageTitle,
    PageSubtitle,
    TeamGrid,
    SectionTitle,
    TeamMember,
    MemberIcon,
    MemberRole,
    MemberDescription,
    TeamStrengthSection,
    StrengthList,
    StrengthItem,
    StrengthIcon,
    StrengthTitle,
    StrengthDescription,
    CTAButtonAnimated
} from './GlobalStyles';

const TeamPage = () => {
    const intl = useIntl();

    const teamMembers = [
        {
            role: intl.formatMessage({ id: 'team.seniorDeveloper' }),
            icon: <FaCode />,
            description: intl.formatMessage({ id: 'team.seniorDeveloperDesc' })
        },
        {
            role: intl.formatMessage({ id: 'team.qaEngineer' }),
            icon: <FaBug />,
            description: intl.formatMessage({ id: 'team.qaEngineerDesc' })
        },
        {
            role: intl.formatMessage({ id: 'team.devOpsEngineer' }),
            icon: <FaServer />,
            description: intl.formatMessage({ id: 'team.devOpsEngineerDesc' })
        },
        {
            role: intl.formatMessage({ id: 'team.uiDesigner' }),
            icon: <FaPalette />,
            description: intl.formatMessage({ id: 'team.uiDesignerDesc' })
        },
        {
            role: intl.formatMessage({ id: 'team.productManager' }),
            icon: <FaChartLine />,
            description: intl.formatMessage({ id: 'team.productManagerDesc' })
        },
        {
            role: intl.formatMessage({ id: 'team.innovationLead' }),
            icon: <FaLightbulb />,
            description: intl.formatMessage({ id: 'team.innovationLeadDesc' })
        }
    ];

    const teamStrengths = [
        { icon: <FaUsers />, title: intl.formatMessage({ id: 'team.strength.collaboration' }), description: intl.formatMessage({ id: 'team.strength.collaborationDesc' }) },
        { icon: <FaRocket />, title: intl.formatMessage({ id: 'team.strength.innovation' }), description: intl.formatMessage({ id: 'team.strength.innovationDesc' }) },
        { icon: <FaLightbulb />, title: intl.formatMessage({ id: 'team.strength.expertise' }), description: intl.formatMessage({ id: 'team.strength.expertiseDesc' }) },
        { icon: <FaChartLine />, title: intl.formatMessage({ id: 'team.strength.results' }), description: intl.formatMessage({ id: 'team.strength.resultsDesc' }) },
    ];

    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": intl.formatMessage({ id: 'team.schemaName' }),
        "description": intl.formatMessage({ id: 'team.schemaDescription' }),
        "url": "https://www.kodikolab.com/team"

    };
    return (
        <PageWrapper>
            <Seo
                title={intl.formatMessage({ id: 'team.seoTitle' })}
                description={intl.formatMessage({ id: 'team.seoDescription' })}
                keywords={intl.formatMessage({ id: 'team.seoKeywords' })}
                canonicalUrl="https://www.kodikolab.com/team"
                schema={schema}
            />
            <Container>
                <PageTitle>{intl.formatMessage({ id: 'team.pageTitle' })}</PageTitle>
                <PageSubtitle>{intl.formatMessage({ id: 'team.pageSubtitle' })}</PageSubtitle>
                <TeamGrid>
                    {teamMembers.map((member, index) => (
                        <TeamMember key={member.id} delay={0.2 * (index + 1)}>
                            <MemberIcon>{member.icon}</MemberIcon>
                            <MemberRole>{member.role}</MemberRole>
                            <MemberDescription>{member.description}</MemberDescription>
                        </TeamMember>
                    ))}
                </TeamGrid>
                <TeamStrengthSection>
                    <SectionTitle>{intl.formatMessage({ id: 'team.strengthTitle' })}</SectionTitle>
                    <StrengthList>
                        {teamStrengths.map((strength, index) => (
                            <StrengthItem key={strength.id} delay={0.2 * (index + 1)}>
                                <StrengthIcon>{strength.icon}</StrengthIcon>
                                <StrengthTitle>{strength.title}</StrengthTitle>
                                <StrengthDescription>{strength.description}</StrengthDescription>
                            </StrengthItem>
                        ))}
                    </StrengthList>
                </TeamStrengthSection>
                <CTAButtonAnimated>{intl.formatMessage({ id: 'team.ctaButton' })}</CTAButtonAnimated>
            </Container>
        </PageWrapper>
    );
};

export default TeamPage;