import React, { useState, useEffect, useCallback  } from 'react';
import { useIntl } from 'react-intl';
import Seo from './seo.js';
import { FaCode, FaMobile, FaCloudUploadAlt, FaFilter, FaArrowRight } from 'react-icons/fa';
import {
    PageWrapper,
    Container,
    ProjectsSectionTitle,
    SearchFilterContainer,
    ProjectImageWrapper,
    ProjectImageOverlay,
    SearchInput,
    FilterButton,
    ProjectGrid,
    ProjectCard,
    ProjectImage,
    ProjectContent,
    ProjectTitle,
    ProjectDescription,
    ProjectDetails,
    ViewDetailsButton,
    LoadingSpinner,
    ErrorMessage,
    FilterContainer,
    FilterPill,
    ProjectIcon
} from './GlobalStyles';

const ProjectsPage = () => {
    const intl = useIntl();
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeFilter, setActiveFilter] = useState(intl.formatMessage({ id: 'projects.filter.all' }));

    const fetchProjects = useCallback(async () => {
        try {
            setIsLoading(true);
            setError(null);
            await new Promise(resolve => setTimeout(resolve, 100));
            const sampleProjects = [
                {
                    id: 'itjuniors',
                    name: 'ITJuniors',
                    description: 'An innovative educational platform for aspiring programmers, featuring interactive coding lessons and personalized learning paths.',
                    imageUrl: 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
                    status: intl.formatMessage({ id: 'projects.status.inProgress' }),
                    clientName: 'ITJuniors',
                    type: intl.formatMessage({ id: 'projects.type.webDev' }),
                    icon: <FaCode />
                },
                {
                    id: 'smartcity',
                    name: 'SmartCity App',
                    description: 'A comprehensive mobile application for smart city management, integrating IoT devices and real-time data analysis.',
                    imageUrl: 'https://images.unsplash.com/photo-1573152143286-0c422b4d2175?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
                    status: intl.formatMessage({ id: 'projects.status.completed' }),
                    clientName: 'Metropolitan Solutions',
                    type: intl.formatMessage({ id: 'projects.type.mobileApp' }),
                    icon: <FaMobile />
                },
                {
                    id: 'cloudoptimize',
                    name: 'CloudOptimize',
                    description: 'Advanced cloud infrastructure optimization solution, leveraging AI to reduce costs and improve performance.',
                    imageUrl: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
                    status: intl.formatMessage({ id: 'projects.status.inProgress' }),
                    clientName: 'TechCloud Solutions',
                    type: intl.formatMessage({ id: 'projects.type.cloudSolution' }),
                    icon: <FaCloudUploadAlt />
                }
            ];
            setProjects(sampleProjects);
        } catch (error) {
            console.error(intl.formatMessage({ id: 'projects.fetchError' }), error);
            setError(intl.formatMessage({ id: 'projects.loadError' }));
        } finally {
            setIsLoading(false);
        }
    }, [intl, setProjects, setIsLoading, setError]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects]);

    const filteredProjects = projects.filter(project =>
        (project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            project.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (activeFilter === intl.formatMessage({ id: 'projects.filter.all' }) || project.type === activeFilter)
    );

    const uniqueProjectTypes = [intl.formatMessage({ id: 'projects.filter.all' }), ...new Set(projects.map(project => project.type))];

    const schema = {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        "name": intl.formatMessage({ id: 'projects.schemaName' }),
        "description": intl.formatMessage({ id: 'projects.schemaDescription' }),
        "url": "https://www.kodikolab.com/projects"
    };

    const renderProjectContent = () => {
        if (isLoading) {
            return <LoadingSpinner aria-label={intl.formatMessage({ id: 'projects.loading' })} />;
        }
        if (error) {
            return <ErrorMessage>{error}</ErrorMessage>;
        }
        return (
            <ProjectGrid>
                {filteredProjects.map((project, index) => (
                    <ProjectCard to={`/project/${project.id}`} key={project.id} delay={0.1 * (index + 1)}>
                        <ProjectImageWrapper>
                            <ProjectImage src={project.imageUrl} alt={`${project.name} ${intl.formatMessage({ id: 'projects.imageAlt' })}`} />
                            <ProjectImageOverlay>
                                <ProjectTitle>{project.name}</ProjectTitle>
                            </ProjectImageOverlay>
                        </ProjectImageWrapper>
                        <ProjectContent>
                            <ProjectDescription>{project.description}</ProjectDescription>
                            <ProjectDetails>
                                <span>{intl.formatMessage({ id: 'projects.statusLabel' })}: {project.status}</span>
                                <span>{intl.formatMessage({ id: 'projects.clientLabel' })}: {project.clientName}</span>
                            </ProjectDetails>
                            <ViewDetailsButton>
                                {intl.formatMessage({ id: 'projects.viewDetails' })} <FaArrowRight />
                            </ViewDetailsButton>
                        </ProjectContent>
                        <ProjectIcon>{project.icon}</ProjectIcon>
                    </ProjectCard>
                ))}
            </ProjectGrid>
        );
    };

    return (
        <>
            <Seo
                title={intl.formatMessage({ id: 'projects.seoTitle' })}
                description={intl.formatMessage({ id: 'projects.seoDescription' })}
                keywords={intl.formatMessage({ id: 'projects.seoKeywords' })}
                canonicalUrl="https://www.kodikolab.com/projects"
                schema={schema}
            />
            <PageWrapper>
                <Container>
                    <ProjectsSectionTitle>{intl.formatMessage({ id: 'projects.pageTitle' })}</ProjectsSectionTitle>

                    <SearchFilterContainer>
                        <SearchInput
                            type="text"
                            placeholder={intl.formatMessage({ id: 'projects.searchPlaceholder' })}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <FilterButton>
                            <FaFilter /> {intl.formatMessage({ id: 'projects.filterButton' })}
                        </FilterButton>
                    </SearchFilterContainer>

                    <FilterContainer>
                        {uniqueProjectTypes.map(type => (
                            <FilterPill
                                key={type}
                                active={activeFilter === type}
                                onClick={() => setActiveFilter(type)}
                            >
                                {type}
                            </FilterPill>
                        ))}
                    </FilterContainer>

                    {renderProjectContent()}
                </Container>
            </PageWrapper>
        </>
    );
};

export default ProjectsPage;