import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaPaperPlane, FaSpinner } from 'react-icons/fa';
import { PageWrapper, fadeInUp, Container } from './GlobalStyles';
import Seo from './seo.js';

const PageTitle = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  animation: ${fadeInUp} 1s ease-out;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  width: 100%;
`;

const ContactInfo = styled.div`
  flex: 1;
  margin-right: 2rem;
  animation: ${fadeInUp} 1s ease-out;
  animation-delay: 0.2s;
  animation-fill-mode: both;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 2rem;
    width: 100%;
  }
`;

const ContactForm = styled.form`
  flex: 2;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 15px;
  animation: ${fadeInUp} 1s ease-out;
  animation-delay: 0.4s;
  animation-fill-mode: both;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
`;

const InfoIcon = styled.span`
  font-size: 1.5rem;
  margin-right: 1rem;
  color: #7efff5;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #7efff5;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #1e3c72;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #7efff5;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #1e3c72;
  resize: vertical;
  min-height: 150px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #7efff5;
  }
`;

const SubmitButton = styled.button`
  background-color: #7efff5;
  color: #1e3c72;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #ffffff;
    color: #4a00e0;
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  svg {
    margin-left: 10px;
  }
`;

const SuccessMessage = styled.div`
  background-color: #4CAF50;
  color: white;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
  text-align: center;
`;

const ErrorMessage = styled.div`
  background-color: #f44336;
  color: white;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 1rem;
  text-align: center;
`;

const ContactPage = () => {
  const location = useLocation();
    const intl = useIntl();
    const schema = {
        "@context": "https://schema.org",
        "@type": "ContactPage",
        "name": intl.formatMessage({ id: 'contact.schemaName' }),
        "description": intl.formatMessage({ id: 'contact.schemaDescription' }),
        "url": "https://www.kodikolab.com/contact"
    };
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '', 
    subject: '',
    message: '',
    ...(location.state || {})
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      // In a real application, you would send this data to a server
      // For this example, we'll simulate an API call
      await new Promise(resolve => setTimeout(resolve, 2000));

      console.log('Sending email to: konrad.litwinowicz@gmail.com');
      console.log('Form data:', formData);

      setSubmitStatus('success');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

    return (
      <>
            <Seo
                title={intl.formatMessage({ id: 'contact.seoTitle' })}
                description={intl.formatMessage({ id: 'contact.seoDescription' })}
                keywords={intl.formatMessage({ id: 'contact.seoKeywords' })}
                canonicalUrl="https://www.kodikolab.com/contact"
                schema={schema}
            />
        <PageWrapper>
          <Container>
            <PageTitle>{intl.formatMessage({ id: 'contact.pageTitle' })}</PageTitle>
            <ContactInfo>
              <h2>{intl.formatMessage({ id: 'contact.infoTitle' })}</h2>
              <p>{intl.formatMessage({ id: 'contact.infoDescription' })}</p>
              <InfoItem>
                <InfoIcon><FaEnvelope /></InfoIcon>
                {intl.formatMessage({ id: 'contact.email' })}
              </InfoItem>
              <InfoItem>
                <InfoIcon><FaPhone /></InfoIcon>
                {intl.formatMessage({ id: 'contact.phone' })}
              </InfoItem>
              <InfoItem>
                <InfoIcon><FaMapMarkerAlt /></InfoIcon>
                {intl.formatMessage({ id: 'contact.address' })}
              </InfoItem>
            </ContactInfo>
            <ContactForm onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="name">{intl.formatMessage({ id: 'contact.form.name' })}</Label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="email">{intl.formatMessage({ id: 'contact.form.email' })}</Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="phone">{intl.formatMessage({ id: 'contact.form.phone' })}</Label>
                <Input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="subject">{intl.formatMessage({ id: 'contact.form.subject' })}</Label>
                <Input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="message">{intl.formatMessage({ id: 'contact.form.message' })}</Label>
                <TextArea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <SubmitButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? intl.formatMessage({ id: 'contact.form.sending' }) : intl.formatMessage({ id: 'contact.form.send' })}
                {isSubmitting ? <FaSpinner /> : <FaPaperPlane />}
              </SubmitButton>
              {submitStatus === 'success' && (
                <SuccessMessage>{intl.formatMessage({ id: 'contact.form.successMessage' })}</SuccessMessage>
              )}
              {submitStatus === 'error' && (
                <ErrorMessage>{intl.formatMessage({ id: 'contact.form.errorMessage' })}</ErrorMessage>
              )}
            </ContactForm>
          </Container>
            </PageWrapper>
        </>
  );
};

export default ContactPage;