import { LOCALES } from './locales';

export const messages = {
  [LOCALES.ENGLISH]: {
        'app.title': 'Kodikolab',
        'project.features.title': 'Key Features',
        'projects.viewDetails': 'View Details',
        'workProcess.title': 'Our Work Process',
        'workProcess.introduction.title': 'How We Work',
        'workProcess.stepsOverview.title': 'Stages of Our Process',
        'workProcess.stepsOverview.description': 'Our work process consists of 8 key stages, each critical to the success of the project. Click on each stage to learn more.',
    'nav.home': 'Home',
    'nav.projects': 'Projects',
    'nav.services': 'Services',
    'nav.about': 'About',
    'nav.contact': 'Contact',
    'banner.title': 'Transform Your Vision with {company}',
    'banner.subtitle': 'Pioneering Software Solutions for the Digital Age',
    'banner.cta': 'Ignite Your Innovation',
    'feature.software': 'Software Development',
    'feature.mobile': 'Mobile Applications',
    'feature.cloud': 'Cloud Solutions',
    'feature.automation': 'Automation',
    'stats.clients': 'Satisfied Clients',
    'stats.projects': 'Completed Projects',
    'stats.satisfaction': 'Client Satisfaction',
    'why.sectionTitle': 'Why Choose Kodikolab?',
    'why.expertTeam.title': 'Expert Team',
    'why.expertTeam.description': 'Skilled professionals dedicated to your success',
    'why.innovativeSolutions.title': 'Innovative Solutions',
    'why.innovativeSolutions.description': 'Cutting-edge technology for modern challenges',
    'why.clientFocused.title': 'Client-Focused Approach',
    'why.clientFocused.description': 'Your vision, our mission',
    'why.leadingTechnology.title': 'Leading Technology',
    'why.leadingTechnology.description': 'Stay ahead with the latest tech advancements',
    'projects.sectionTitle': 'Recent Projects',
    'projects.random.name': 'Innovative Tech Solution',
    'projects.random.description': 'A cutting-edge project leveraging the latest technologies to solve complex business challenges.',
    'projects.status.inProgress': 'In Progress',
    'projects.random.client': 'Tech Innovators Inc.',
    'projects.imageAlt': 'project',
    'projects.status': 'Status',
    'projects.client': 'Client',
    'projects.viewAll': 'View All Projects',
        'news.sectionTitle': 'Latest News & Upcoming Events',
        'project.technologies.title': 'Technologies',
        'project.timeline.title': 'Project Timeline',
    'news.ai.title': 'Kodikolab launches new AI service',
    'news.ai.description': 'Revolutionizing business processes with cutting-edge AI solutions',
    'news.webinar.title': 'Upcoming webinar: Future of Web Development',
    'news.webinar.description': 'Join us on June 15th for insights into emerging web technologies',
    'news.hiring.title': 'We are hiring: Senior React Developer',
    'news.hiring.description': 'Be part of our innovative team and shape the future of web applications',
    'news.readMore': 'Read More',
    'footer.company': 'Kodikolab',
    'footer.slogan': 'Innovative solutions for your digital needs.',
    'footer.social.facebook': 'Facebook',
    'footer.social.twitter': 'Twitter',
    'footer.social.linkedin': 'LinkedIn',
    'footer.social.instagram': 'Instagram',
    'footer.quickLinks.title': 'Quick Links',
    'footer.quickLinks.home': 'Home',
    'footer.quickLinks.projects': 'Projects',
    'footer.quickLinks.about': 'About',
    'footer.quickLinks.contact': 'Contact',
    'footer.contact.title': 'Contact Us',
    'footer.contact.email': 'info@kodikolab.com',
    'footer.contact.phone': '+1 (123) 456-7890',
    'footer.contact.address': '123 Tech Street, Silicon Valley, CA 94000',
    'footer.copyright': '© {year} Kodikolab. All rights reserved.',
    'projects.pageTitle': 'Our Projects',
    'projects.searchPlaceholder': 'Search projects...',
    'projects.filterButton': 'Filter',
    'projects.loading': 'Loading projects',
    'projects.statusLabel': 'Status',
        'projects.clientLabel': 'Client',
        'workProcess.introduction.paragraph1': 'At Kodikolab, we believe that an effective work process is key to the success of every project. Our proven process combines flexibility with precision, ensuring optimal results for our clients.',
        'workProcess.introduction.paragraph2': 'From the initial consultation to continuous optimization, each stage of our process is carefully designed to ensure the highest quality, efficiency, and client satisfaction.',
        'workProcess.benefits.title': 'Benefits of Our Approach',
        'workProcess.benefits.description': 'Our unique approach to the work process brings many benefits to our clients:',
        'workProcess.benefits.list': 'Transparency and predictability, Faster value delivery, Flexibility in adapting to changes, High quality of the final product, Cost and time optimization, Continuous improvement',
        'workProcess.consultation.title': 'Consultation',
        'workProcess.consultation.shortDescription': 'Understanding your needs',
        'workProcess.consultation.benefits': 'Precise understanding of needs, Tailored solutions, Realistic expectations',
        'workProcess.planning.title': 'Planning',
        'workProcess.planning.shortDescription': 'Creating the roadmap',
        'workProcess.planning.benefits': 'Clear project vision, Efficient resource allocation, Risk mitigation',
        'workProcess.development.benefits': 'High-quality code, Scalable solutions, Efficient development process',
        'workProcess.testing.title': 'Testing',
        'workProcess.testing.shortDescription': 'Ensuring quality',
        'workProcess.testing.benefits': 'Bug-free software, Enhanced user experience, Reduced post-launch issues',
        'services.benefits.innovation': 'Cutting-edge innovation',
        'project.itjuniors.seo.title': 'ITJuniors: Educational Platform for Future Programmers | Kodikolab',
        'project.itjuniors.seo.description': 'Discover ITJuniors, an innovative e-learning platform by Kodikolab, designed to support aspiring programmers in their journey to success in the IT industry.',
        'project.itjuniors.seo.keywords': 'ITJuniors, educational platform, programming, e-learning, Kodikolab, IT industry',

        'project.smartcity.seo.title': 'SmartCity App: Comprehensive Urban Management Solution | Kodikolab',
        'project.smartcity.seo.description': 'Explore our SmartCity App, a comprehensive mobile application for smart city management, integrating IoT devices and real-time data analysis.',
        'project.smartcity.seo.keywords': 'SmartCity App, urban management, IoT, data analysis, Kodikolab, smart city',
        'services.benefits.costEffective': 'Cost-effective solutions',
        'workProcess.deployment.shortDescription': 'Going live',
        'workProcess.deployment.benefits': 'Smooth transition to production, Minimal disruption, Reliable launch',
        'workProcess.maintenance.title': 'Maintenance',
        'workProcess.maintenance.shortDescription': 'Ongoing support',
        'workProcess.maintenance.benefits': 'Consistent performance, Up-to-date security, Peace of mind',
        'workProcess.optimization.title': 'Optimization',
        'workProcess.optimization.shortDescription': 'Continuous improvement',
        'workProcess.optimization.details': 'After deployment, we continuously monitor performance and gather data to identify areas for optimization. We implement improvements that enhance efficiency, scalability, and user experience.',
        'workProcess.optimization.benefits': 'Increased efficiency, Better scalability, Cost optimization',
        'workProcess.feedback.title': 'Feedback and Iteration',
        'workProcess.feedback.shortDescription': 'Continuous refinement',
        'workProcess.feedback.details': 'We regularly collect feedback from users and clients. This valuable information is used for continuous product improvement and planning future features.',
        'workProcess.feedback.benefits': 'User-centric product, Continuous improvements, Long-term value',
        'team.pageSubtitle': 'A group of passionate experts dedicated to turning your ideas into reality',
        'team.seniorDeveloper': 'Senior Developer',
        'team.seniorDeveloperDesc': 'Crafting robust and scalable software solutions across various platforms.',
        'team.qaEngineer': 'QA Engineer',
        'team.qaEngineerDesc': 'Ensuring impeccable quality through rigorous testing and quality assurance processes.',
        'team.devOpsEngineer': 'DevOps Engineer',
        'team.devOpsEngineerDesc': 'Optimizing our development pipeline and infrastructure for peak performance.',
        'team.uiDesigner': 'UI/UX Designer',
        'team.uiDesignerDesc': 'Creating beautiful, intuitive interfaces that delight users and drive engagement.',
        'team.productManager': 'Product Manager',
        'team.productManagerDesc': 'Steering our product strategy to deliver maximum value and stay ahead of market trends.',
        'team.innovationLead': 'Innovation Lead',
        'team.innovationLeadDesc': 'Pushing boundaries with cutting-edge technologies to keep us at the forefront of the industry.',
        'team.strengthTitle': 'Our Team Strengths',
        'team.seoTitle': 'Our Expert Team - Kodikolab Software Development Professionals',
        'team.seoDescription': 'Meet the talented professionals behind Kodikolab\'s innovative software solutions. Our diverse team of experts is dedicated to delivering exceptional results.',
        'team.seoKeywords': 'Kodikolab team, software development experts, IT professionals, tech innovators',
        'team.schemaName': 'Kodikolab Development Team',
        'team.schemaDescription': 'The expert software development team at Kodikolab, delivering innovative and high-quality solutions',
        'team.strength.collaboration': 'Seamless Collaboration',
        'team.strength.collaborationDesc': 'We work together seamlessly to deliver outstanding results.',
        'team.strength.innovation': 'Driven by Innovation',
        'team.strength.innovationDesc': 'Constantly exploring new ideas and technologies to stay ahead.',
        'team.strength.expertise': 'Deep Expertise',
        'team.strength.expertiseDesc': 'Years of experience across various tech domains.',
        'team.strength.results': 'Results-Oriented',
        'team.strength.resultsDesc': 'Focused on delivering tangible value to our clients.',
        'team.ctaButton': 'Work With Us',
        'services.ourProcess': 'Our Development Process',
        "project.itjuniors.title": "ITJuniors: Educational Platform for Future Programmers",
        "project.itjuniors.subtitle": "Innovative e-learning solution for the IT industry",
        "project.itjuniors.features.title": "Key Features",
        "project.itjuniors.features.personalized.title": "Personalized Learning Paths",
        "project.itjuniors.features.personalized.description": "Tailored curricula for various skill levels and career goals.",
        "project.itjuniors.features.interactive.title": "Interactive Coding Environment",
        "project.itjuniors.features.interactive.description": "Built-in code editor with real-time programming task execution capabilities.",
        "project.itjuniors.features.mentoring.title": "Mentoring and Expert Support",
        "project.itjuniors.features.mentoring.description": "Access to experienced programmers and industry mentors.",
        "project.itjuniors.features.mobile.title": "Mobile Access",
        "project.itjuniors.features.mobile.description": "Full functionality on mobile devices, enabling learning anytime, anywhere.",
        "project.itjuniors.features.security.title": "Security and Privacy",
        "project.itjuniors.features.security.description": "Advanced mechanisms to protect user data and learning progress.",
        "project.itjuniors.technologies.title": "Technologies",
        "project.itjuniors.technologies.dotnet": ".NET Core",
        "project.itjuniors.technologies.react": "React",
        'home.seoTitle': 'Kodikolab - Pioneering Software Solutions for the Digital Age',
        'home.seoDescription': 'Kodikolab offers cutting-edge software development services, including web and mobile applications, cloud solutions, and automation.',
        'home.seoKeywords': 'software development, web applications, mobile apps, cloud solutions, automation, Kodikolab',
        'home.schemaDescription': 'Kodikolab - Innovative software solutions for the digital age',
        'projects.seoTitle': 'Our Projects - Kodikolab Software Solutions',
        'notFound.title': 'Page Not Found',
        'notFound.message': 'Sorry, but the page you are looking for does not exist.',
        'notFound.linkText': 'Return to Home Page',
        'projects.seoDescription': 'Explore Kodikolabs portfolio of innovative software projects, showcasing our expertise in web, mobile, and cloud development.',
        'projects.seoKeywords': 'software projects, web development, mobile apps, cloud solutions, Kodikolab portfolio',
        'projects.schemaName': 'Kodikolab Projects',
        'projects.schemaDescription': 'A collection of innovative software projects developed by Kodikolab',
        'about.seoTitle': 'About Kodikolab - Our Story, Mission, and Values',
        'about.seoDescription': 'Learn about Kodikolabs journey, our mission to deliver innovative software solutions, and the values that drive our success.',
        'about.seoKeywords': 'Kodikolab, software company, our story, mission, values, team',
        'about.schemaName': 'About Kodikolab',
        'about.schemaDescription': 'Information about Kodikolab, our mission, values, and team',
        'contact.seoTitle': 'Contact Kodikolab - Get in Touch for Software Solutions',
        'contact.seoDescription': 'Reach out to Kodikolab for innovative software solutions. Contact us for consultations, quotes, or to discuss your project needs.',
        'contact.seoKeywords': 'contact Kodikolab, software development inquiry, project consultation',
        'contact.schemaName': 'Contact Kodikolab',
        'contact.schemaDescription': 'Contact information and form for reaching out to Kodikolab',
        'services.seoTitle': 'Our Services - Kodikolab Software Development Solutions',
        'services.seoDescription': 'Discover Kodikolabs comprehensive software development services, including web and mobile app development, cloud solutions, and more.',
        'services.seoKeywords': 'software development services, web development, mobile apps, cloud solutions, Kodikolab',
        'services.schemaName': 'Kodikolab Services',
        'services.schemaDescription': 'Overview of software development services offered by Kodikolab',
        "project.itjuniors.technologies.sqlserver": "SQL Server",
        "project.itjuniors.technologies.azure": "Azure Cloud Services",
        "project.itjuniors.timeline.title": "Project Timeline",
        "project.itjuniors.timeline.concept.title": "Concept and Requirements Analysis",
        "project.itjuniors.timeline.concept.description": "Defining project goals, analyzing user needs, and determining main platform functionalities.",
        "project.itjuniors.timeline.concept.date": "May 2023",
        "project.itjuniors.timeline.uxui.title": "UX/UI Design",
        "project.itjuniors.timeline.uxui.description": "Creating an intuitive user interface and developing user paths for different target groups.",
        "project.itjuniors.timeline.uxui.date": "June - July 2023",
        'services.expertiseDescription': 'Our team of experts specializes in delivering cutting-edge software solutions tailored to your unique business needs. With years of experience across various industries, we bring innovative approaches to every project.',
        'services.processDescription': 'Our proven development process ensures high-quality results and smooth collaboration. From initial consultation to final deployment, we keep you involved every step of the way.',
        'services.process.step1': 'Consultation and Planning',
        'services.process.step2': 'Design and Development',
        'services.process.step3': 'Testing and Quality Assurance',
        'services.process.step4': 'Deployment and Support',
        'services.contactUs': 'Contact Us',
        'services.viewPortfolio': 'View Our Portfolio',
        'services.faq': 'Frequently Asked Questions',
        'services.faq1.question': 'What types of projects do you handle?',
        'services.faq1.answer': 'We handle a wide range of projects, from small business websites to complex enterprise software solutions. Our expertise covers web development, mobile apps, custom software, and more.',
        'services.faq2.question': 'How long does a typical project take?',
        'services.faq2.answer': 'Project duration varies depending on complexity and scope. A simple website might take 4-6 weeks, while a complex software solution could take several months. We\'ll provide a detailed timeline during our initial consultation.',
        'services.faq3.question': 'Do you provide ongoing support after project completion?',
        'services.faq3.answer': 'Yes, we offer ongoing support and maintenance services to ensure your software continues to perform optimally. We can tailor a support package to meet your specific needs.',
        "project.itjuniors.timeline.backend.title": "Backend Development",
        "project.itjuniors.timeline.backend.description": "Implementing business logic, database integration, and creating API using .NET Core.",
        "project.itjuniors.timeline.backend.date": "August - September 2023",
        "project.itjuniors.timeline.frontend.title": "Frontend Development",
        "project.itjuniors.timeline.frontend.description": "Building a responsive user interface using React and integrating with the backend.",
        "project.itjuniors.timeline.frontend.date": "September - October 2023",
        "project.itjuniors.timeline.testing.title": "Testing and Optimization",
        "project.itjuniors.timeline.testing.description": "Conducting functional, performance, and security tests. Optimizing code and infrastructure.",
        "project.itjuniors.timeline.testing.date": "November 2023",
        'workProcess.seoTitle': 'Our Work Process - Kodikolab Software Development Methodology',
        'workProcess.seoDescription': 'Discover Kodikolab\'s efficient and effective software development process, from consultation to continuous improvement.',
        'workProcess.seoKeywords': 'work process, software development methodology, Kodikolab, agile development',
        'workProcess.schemaName': 'Kodikolab Work Process',
        'workProcess.schemaDescription': 'Detailed overview of Kodikolab\'s software development process and methodology',
        "project.itjuniors.timeline.softlaunch.title": "Soft Launch",
        "project.itjuniors.timeline.softlaunch.description": "Making the platform available to a limited group of users to gather feedback and make further improvements.",
        "project.itjuniors.timeline.softlaunch.date": "December 2023",
        "project.itjuniors.timeline.launch.title": "Official Launch",
        "project.itjuniors.timeline.launch.description": "Full launch of the ITJuniors platform for all users.",
        "project.itjuniors.timeline.launch.date": "March 2024",
        "project.itjuniors.cta.title": "Realize Your Project with KodikoLab",
        "project.itjuniors.cta.description": "Are you interested in creating an innovative educational platform or other advanced technological solution? Our team of experts at KodikoLab is ready to help you transform your vision into reality. Contact us to discuss your project and start collaboration.",
        "project.itjuniors.cta.button": "Contact Us",
        'project.developmentProcess': 'Our Development Process',
        'project.process.ideation.title': 'Ideation',
        'project.process.ideation.description': 'Brainstorming and conceptualizing the project',
        'project.process.planning.title': 'Planning',
        'project.process.planning.description': 'Defining project scope, timeline, and resources',
        'project.process.development.title': 'Development',
        'project.process.development.description': 'Building the application using .NET, React, and SQL',
        'project.process.testing.title': 'Testing',
        'project.process.testing.description': 'Rigorous quality assurance and bug fixing',
        'project.process.deployment.title': 'Deployment',
        'project.process.deployment.description': 'Launching the application for user access',
        'project.process.monitoring.title': 'Monitoring',
        'project.process.monitoring.description': 'Tracking performance and user feedback',
        'project.process.maintenance.title': 'Maintenance',
        'project.process.maintenance.description': 'Ongoing updates and improvements',
        'project.timeline': 'Project Timeline',
        'project.timeline.description': 'ITJuniors will be available for preview in November, with the full production release scheduled for March. This phased approach allows us to gather user feedback and refine the platform before the official launch.',
        'project.cta.description': 'Are you looking to bring your digital project to life? KodikoLab specializes in creating custom web applications and platforms tailored to your specific needs. Let us help you turn your vision into reality.',
        'project.itjuniors.name': 'ITJuniors',
        'project.itjuniors.description': 'An innovative educational platform for children aged 7-11 to learn programming through Minecraft Education Edition.',
        'project.stages.concept': 'Concept',
        'project.stages.concept.description': 'Developing main project assumptions and analyzing educational needs for young learners.',
        'project.stages.planning': 'Planning',
        'project.stages.planning.description': 'Creating a detailed project plan, curriculum, and learning objectives.',
        'project.stages.design': 'UX/UI Design',
        'project.stages.design.description': 'Designing child-friendly interfaces and Minecraft-based learning environments.',
        'project.stages.development': 'Development',
        'project.stages.development.description': 'Implementing the platform and integrating with Minecraft Education Edition.',
        'project.stages.testing': 'Testing',
        'project.stages.testing.description': 'Conducting thorough testing with focus groups of children and educators.',
        'project.stages.launch': 'Launch',
        'project.stages.launch.description': 'Official launch of the ITJuniors platform.',
        'project.stages.planned.from': 'Planned from {date}',
        'project.stages.planned.for': 'Planned for {date}',
        'project.keyFeatures': 'Key Features',
        'services.pageTitle': 'Our Services',
        'services.ourExpertise': 'Our Expertise',
        'services.individualApproach': 'Individual Approach',
        'services.approachDescription': 'At Kodikolab, we believe that each project is unique. Our solutions are tailored to the specific needs of your business.',
        'services.benefits.tailored': 'Tailored solutions',
        'services.benefits.expertise': 'Years of industry experience',
        'services.benefits.quality': 'Highest code quality',
        'services.benefits.support': '24/7 support',
        'services.readyToStart': 'Ready to Start?',
        'services.ctaDescription': 'Contact us today to discuss your project and start creating innovative solutions for your business.',
        'services.showDetails': 'Show Details',
        'services.hideDetails': 'Hide Details',
        'project.features.minecraft': 'Learning through Minecraft Education Edition',
        'project.features.ageAppropriate': 'Age-appropriate content for 7-11 year olds',
        'project.features.interactiveLessons': 'Interactive coding lessons in Minecraft world',
        'project.features.progressTracking': 'Gamified progress tracking system',
        'project.technologies': 'Technologies',
        'project.progress': 'Project Progress',
        'project.faq': 'FAQ',
        'project.faq.launch': 'When will ITJuniors be available?',
        'project.faq.launch.answer': 'We plan to launch ITJuniors in the third quarter of 2024.',
        'project.faq.age': 'Is ITJuniors suitable for children outside the 7-11 age range?',
        'project.faq.age.answer': 'The content is specifically designed for children aged 7-11, but were considering expanding our offer for different age groups in the future.',
    'loading': 'Loading...',
    'projects.fetchError': 'Failed to fetch projects',
    'projects.loadError': 'Failed to load projects. Displaying sample data.',
    'projects.filter.all': 'All',
    'projects.status.completed': 'Completed',
    'projects.status.planning': 'Planning',
    'projects.client.techInnovators': 'Tech Innovators Inc.',
    'projects.client.globalSolutions': 'Global Solutions Ltd.',
    'projects.client.futureSystems': 'Future Systems Co.',
    'projects.client.digitalDynamics': 'Digital Dynamics',
    'projects.client.ecoTech': 'EcoTech Solutions',
    'projects.client.smartInnovations': 'Smart Innovations',
    'projects.type.webDev': 'Web Development',
    'projects.type.mobileApp': 'Mobile App',
    'projects.type.cloudSolution': 'Cloud Solution',
    'projects.type.aiIntegration': 'AI Integration',
    'projects.type.iotPlatform': 'IoT Platform',
    'projects.type.blockchain': 'Blockchain Implementation',
    'projects.description': 'A cutting-edge {type} project leveraging the latest technologies to solve complex business challenges. This project aims to revolutionize the industry with its unique approach and advanced features.',
    'services.net.title': '.NET Development',
    'services.net.description': 'Expert .NET development for robust, scalable applications',
    'services.react.title': 'React Development',
    'services.react.description': 'Creating dynamic and responsive user interfaces with React',
    'services.angular.title': 'Angular Development',
    'services.angular.description': 'Building powerful single-page applications with Angular',
    'services.database.title': 'Database Design',
    'services.database.description': 'Efficient and secure database solutions for your data needs',
    'services.backend.title': 'Backend Development',
    'services.backend.description': 'Solid backend architectures to power your applications',
    'services.wordpress.title': 'WordPress Development',
    'services.wordpress.description': 'Custom WordPress themes and plugins for your unique needs',
    'services.custom.title': 'Custom Solutions',
    'services.custom.description': 'Tailored software solutions to meet your unique requirements',
        'about.pageTitle': 'About KodikoLab',
        'services.calculator.selectFramework': 'Select framework',
        'services.calculator.selectDatabase': 'Select database',
        'services.calculator.selectDeployment': 'Select deployment option',
    'about.story.title': 'Our Story',
    'about.story.paragraph1': 'Founded in October 2024, KodikoLab emerged from a passion for crafting exceptional digital solutions. Our team of seasoned developers and creative minds came together with a shared vision: to transform ideas into powerful, efficient, and innovative software applications.',
    'about.story.paragraph2': 'Since our inception, weve been on a mission to elevate the standard of software development. We believe that great code is not just about functionality—its about creating elegant solutions that drive business growth and user satisfaction.',
    'about.mission.title': 'Our Mission',
    'about.mission.paragraph1': 'At KodikoLab, our mission is to empower businesses through transformative digital solutions. We strive to create software that not only meets but exceeds our clients expectations, fostering innovation and driving success in the digital age.',
    'about.mission.paragraph2': 'We are committed to delivering excellence in every line of code, every user interface, and every digital experience we create. Our goal is to be at the forefront of technological advancement, continuously pushing the boundaries of whats possible in software development.',
    'about.values.title': 'Our Values',
    'about.values.description': 'At the heart of KodikoLab are the values that guide our work and shape our culture. These principles are the foundation of our success and the driving force behind our commitment to excellence.',
    'about.values.codeExcellence.title': 'Code Excellence',
    'about.values.codeExcellence.description': 'We are committed to writing clean, efficient, and maintainable code that stands the test of time.',
    'about.values.innovation.title': 'Innovation',
    'about.values.innovation.description': 'We constantly explore new technologies and methodologies to deliver cutting-edge solutions.',
    'about.values.clientPartnership.title': 'Client Partnership',
    'about.values.clientPartnership.description': 'We believe in building strong, long-lasting relationships with our clients, becoming true partners in their success.',
    'about.values.continuousGrowth.title': 'Continuous Growth',
    'about.values.continuousGrowth.description': 'We are dedicated to continuous learning and improvement, staying ahead in the fast-paced world of technology.',
    'about.team.title': 'Our Team',
    'about.team.description1': 'KodikoLab is powered by a team of passionate and experienced professionals. With years of collective experience in diverse areas of software development, our experts bring a wealth of knowledge and creativity to every project.',
    'about.team.description2': 'From seasoned .NET developers to React and Angular specialists, database architects to UX/UI designers, our team is equipped to handle projects of any scale and complexity. We pride ourselves on our collaborative approach, combining our individual strengths to deliver holistic solutions that drive real results.',
    'about.expertise.title': 'Our Expertise',
    'about.expertise.introduction': 'At KodikoLab, we specialize in a wide range of technologies and services, including:',
    'about.expertise.list': '.NET Development, React and Angular Frontend Solutions, Database Design and Optimization, Custom Software Development, Web and Mobile Application Development, Cloud Solutions and Integration',
    'about.expertise.conclusion': 'Our versatility allows us to cater to diverse client needs, from startups looking for MVP development to large enterprises requiring complex, scalable solutions.',
    'about.commitment.title': 'Our Commitment',
    'about.commitment.paragraph1': 'At KodikoLab, were more than just a software development company—were your technology partner. Were committed to understanding your unique challenges and goals, and to delivering solutions that not only meet your current needs but also position you for future success.',
    'about.commitment.paragraph2': 'Whether youre a small business looking to establish your digital presence or a large corporation aiming to streamline operations through custom software, KodikoLab is here to turn your vision into reality. Join us on this exciting journey of digital transformation, and lets create something extraordinary together.',
    'contact.pageTitle': 'Get in Touch',
    'contact.infoTitle': 'We\'d Love to Hear from You',
    'contact.infoDescription': 'Ready to start your next project or have questions about our services? Reach out to us and let\'s create something amazing together!',
    'contact.email': 'info@kodikolab.com',
    'contact.phone': '+1 (555) 123-4567',
    'contact.address': '123 Tech Street, Innovation City, 12345',
    'contact.form.name': 'Name',
    'contact.form.email': 'Email',
    'contact.form.phone': 'Phone Number',
    'contact.form.subject': 'Subject',
    'contact.form.message': 'Message',
    'contact.form.send': 'Send Message',
    'contact.form.sending': 'Sending...',
    'contact.form.successMessage': 'Thank you for your message! We\'ll get back to you soon.',
        'contact.form.errorMessage': 'Oops! Something went wrong. Please try again later.',
        'nav.team': 'Team',
        'nav.workProcess': 'Work Process',
        'team.pageTitle': 'Our Team',
        'team.pageDescription': 'Meet the expert team behind Kodikolab\'s innovative solutions.',
        'team.pageKeywords': 'team, experts, developers, designers, Kodikolab',
        'workProcess.consultation.details': 'We start by thoroughly understanding your business needs and project requirements.',
        'workProcess.planning.details': 'We create a detailed project plan, including timelines, milestones, and resource allocation.',
        'workProcess.development.title': 'Development',
        'workProcess.development.shortDescription': 'Bringing ideas to life',
        'workProcess.development.details': 'Our expert team transforms plans into reality, writing clean, efficient code.',
        'workProcess.testing.details': 'Rigorous testing ensures your solution meets the highest quality standards.',
        'workProcess.deployment.title': 'Deployment',
        'workProcess.deployment.details': 'We carefully launch your solution, ensuring a smooth transition to production.',
        'workProcess.maintenance.details': 'We provide continuous support and updates to keep your solution running smoothly.',
        'services.customDevelopment.title': 'Custom Software Development',
        'services.customDevelopment.description': 'Tailored software solutions to meet your unique business needs',
        'services.customDevelopment.details': 'We create bespoke software applications that are perfectly aligned with your business processes and goals.',
        'services.frontendDevelopment.title': 'Frontend Development',
        'services.frontendDevelopment.description': 'Creating engaging and responsive user interfaces',
        'services.frontendDevelopment.details': 'Our frontend experts craft intuitive and visually appealing interfaces that enhance user experience.',
        'services.backendDevelopment.title': 'Backend Development',
        'services.backendDevelopment.description': 'Building robust and scalable server-side applications',
        'services.backendDevelopment.details': 'We develop powerful backend systems that ensure smooth data processing and application performance.',
        'services.mobileDevelopment.title': 'Mobile App Development',
        'services.mobileDevelopment.description': 'Developing native and cross-platform mobile applications',
        'services.mobileDevelopment.details': 'From iOS to Android, we create mobile apps that deliver exceptional user experiences across all devices.',
        'services.databaseDesign.title': 'Database Design',
        'services.databaseDesign.description': 'Designing efficient and secure database solutions',
        'services.databaseDesign.details': 'We architect database systems that ensure data integrity, security, and optimal performance.',
        'services.cloudServices.title': 'Cloud Services',
        'services.cloudServices.description': 'Leveraging cloud technologies for scalable solutions',
        'services.cloudServices.details': 'We help businesses harness the power of cloud computing for improved flexibility and cost-efficiency.',
        'services.containerization.title': 'Containerization',
        'services.containerization.description': 'Implementing container-based solutions for improved deployment',
        'services.containerization.details': 'We use Docker and Kubernetes to streamline application deployment and scaling.',
        'services.security.title': 'Cybersecurity',
        'services.security.description': 'Implementing robust security measures to protect your digital assets',
        'services.security.details': 'Our security experts ensure your applications and data are protected against cyber threats.',
        'services.maintenance.title': 'Maintenance and Support',
        'services.maintenance.description': 'Providing ongoing support and updates for your software',
        'services.maintenance.details': 'We offer comprehensive maintenance services to keep your software running smoothly and up-to-date.',
        'services.performanceOptimization.title': 'Performance Optimization',
        'services.performanceOptimization.description': 'Enhancing software performance for better efficiency',
        'services.performanceOptimization.details': 'We fine-tune your applications to achieve optimal speed and resource utilization.',
        'services.devops.title': 'DevOps Services',
        'services.devops.description': 'Implementing DevOps practices for improved development workflows',
        'services.devops.details': 'We integrate DevOps methodologies to streamline development, testing, and deployment processes.',
        'services.consultancy.title': 'IT Consultancy',
        'services.consultancy.description': 'Providing expert advice on technology strategies',
        'services.consultancy.details': 'Our consultants help you make informed decisions about your IT infrastructure and software investments.',
        'services.analytics.title': 'Data Analytics',
        'services.analytics.description': 'Transforming data into actionable insights',
        'services.analytics.details': 'We implement data analytics solutions to help you make data-driven decisions and gain competitive advantages.'
  },
  [LOCALES.POLISH]: {
    'app.title': 'Kodikolab',
    'nav.home': 'Strona główna',
    'nav.projects': 'Projekty',
    'nav.services': 'Usługi',
      'nav.about': 'O nas',
      'services.calculator.selectFramework': 'Wybierz framework',
      'services.calculator.selectDatabase': 'Wybierz bazę danych',
      'services.calculator.selectDeployment': 'Wybierz opcję wdrożenia',
      'team.pageSubtitle': 'Grupa pasjonatów ekspertów dedykowana przekształcaniu Twoich pomysłów w rzeczywistość',
      'team.seniorDeveloper': 'Starszy Programista',
      'team.seniorDeveloperDesc': 'Tworzy solidne i skalowalne rozwiązania programistyczne na różnych platformach.',
      'team.qaEngineer': 'Inżynier QA',
      'team.qaEngineerDesc': 'Zapewnia nieskazitelną jakość poprzez rygorystyczne procesy testowania i zapewniania jakości.',
      'team.devOpsEngineer': 'Inżynier DevOps',
      'team.devOpsEngineerDesc': 'Optymalizuje nasz pipeline rozwojowy i infrastrukturę dla maksymalnej wydajności.',
      'team.uiDesigner': 'Projektant UI/UX',
      'team.uiDesignerDesc': 'Tworzy piękne, intuicyjne interfejsy, które zachwycają użytkowników i zwiększają zaangażowanie.',
      'team.productManager': 'Menedżer Produktu',
      'team.productManagerDesc': 'Kieruje naszą strategią produktową, aby dostarczyć maksymalną wartość i wyprzedzać trendy rynkowe.',
      'team.innovationLead': 'Lider Innowacji',
      'team.innovationLeadDesc': 'Przesuwa granice dzięki najnowocześniejszym technologiom, utrzymując nas na czele branży.',
      'team.strengthTitle': 'Mocne Strony Naszego Zespołu',
      'team.strength.collaboration': 'Płynna Współpraca',
      'team.strength.collaborationDesc': 'Pracujemy razem bez zarzutu, aby dostarczać wyjątkowe rezultaty.',
      'team.strength.innovation': 'Napędzani Innowacją',
      'team.strength.innovationDesc': 'Stale eksplorujemy nowe pomysły i technologie, aby być o krok do przodu.',
      'team.strength.expertise': 'Głęboka Ekspertyza',
      'team.strength.expertiseDesc': 'Lata doświadczenia w różnych dziedzinach technologicznych.',
      'team.strength.results': 'Zorientowani na Wyniki',
      'team.strength.resultsDesc': 'Skupieni na dostarczaniu namacalnej wartości naszym klientom.',
      'projects.viewDetails': 'Zobacz Szczegóły',
      'workProcess.title': 'Nasz Proces Pracy',
      'workProcess.introduction.title': 'Jak Pracujemy',
      'workProcess.stepsOverview.title': 'Etapy Naszego Procesu',
      'workProcess.stepsOverview.description': 'Nasz proces pracy składa się z 8 kluczowych etapów, z których każdy ma krytyczne znaczenie dla sukcesu projektu. Kliknij na każdy etap, aby dowiedzieć się więcej.',
      'team.ctaButton': 'Pracuj z Nami',
    'nav.contact': 'Kontakt',
    'banner.title': 'Przekształć swoją wizję z firmą {company}',
    'banner.subtitle': 'Innowacyjne rozwiązania programistyczne dla ery cyfrowej',
    'banner.cta': 'Rozpal swoją innowacyjność',
    'feature.software': 'Tworzenie oprogramowania',
    'feature.mobile': 'Aplikacje mobilne',
    'feature.cloud': 'Rozwiązania chmurowe',
    'feature.automation': 'Automatyzacja',
    'stats.clients': 'Zadowolonych klientów',
    'stats.projects': 'Zrealizowanych projektów',
    'stats.satisfaction': 'Satysfakcja klientów',
    'why.sectionTitle': 'Dlaczego warto wybrać Kodikolab?',
    'why.expertTeam.title': 'Zespół ekspertów',
    'why.expertTeam.description': 'Wykwalifikowani specjaliści zaangażowani w Twój sukces',
      'why.innovativeSolutions.title': 'Innowacyjne rozwiązania',
      'project.technologies.title': 'Technologie',
      'project.timeline.title': 'Harmonogram projektu',
    'why.innovativeSolutions.description': 'Nowoczesne technologie dla współczesnych wyzwań',
    'why.clientFocused.title': 'Podejście zorientowane na klienta',
    'why.clientFocused.description': 'Twoja wizja, nasza misja',
    'why.leadingTechnology.title': 'Wiodąca technologia',
    'why.leadingTechnology.description': 'Bądź na czele z najnowszymi osiągnięciami technologicznymi',
    'projects.sectionTitle': 'Ostatnie Projekty',
    'projects.random.name': 'Innowacyjne Rozwiązanie Technologiczne',
    'projects.random.description': 'Projekt wykorzystujący najnowsze technologie do rozwiązywania złożonych wyzwań biznesowych.',
    'projects.status.inProgress': 'W trakcie realizacji',
    'projects.random.client': 'Innowatorzy Technologii Sp. z o.o.',
    'projects.imageAlt': 'projekt',
    'projects.status': 'Status',
    'projects.client': 'Klient',
    'projects.viewAll': 'Zobacz Wszystkie Projekty',
    'news.sectionTitle': 'Najnowsze Wiadomości i Nadchodzące Wydarzenia',
    'news.ai.title': 'Kodikolab uruchamia nową usługę AI',
    'news.ai.description': 'Rewolucjonizujemy procesy biznesowe za pomocą najnowocześniejszych rozwiązań AI',
    'news.webinar.title': 'Nadchodzący webinar: Przyszłość Rozwoju Stron Internetowych',
    'news.webinar.description': 'Dołącz do nas 15 czerwca, aby poznać najnowsze technologie webowe',
    'news.hiring.title': 'Zatrudniamy: Starszy Programista React',
    'news.hiring.description': 'Dołącz do naszego innowacyjnego zespołu i kształtuj przyszłość aplikacji internetowych',
    'news.readMore': 'Czytaj Więcej',
    'footer.company': 'Kodikolab',
    'footer.slogan': 'Innowacyjne rozwiązania dla Twoich potrzeb cyfrowych.',
    'footer.social.facebook': 'Facebook',
    'footer.social.twitter': 'Twitter',
    'footer.social.linkedin': 'LinkedIn',
    'footer.social.instagram': 'Instagram',
    'footer.quickLinks.title': 'Szybkie Linki',
    'footer.quickLinks.home': 'Strona główna',
    'footer.quickLinks.projects': 'Projekty',
      'footer.quickLinks.about': 'O nas',
      'team.seoTitle': 'Nasz Zespół Ekspertów - Profesjonaliści Rozwoju Oprogramowania Kodikolab',
      'team.seoDescription': 'Poznaj utalentowanych profesjonalistów stojących za innowacyjnymi rozwiązaniami software\'owymi Kodikolab. Nasz zróżnicowany zespół ekspertów jest oddany dostarczaniu wyjątkowych rezultatów.',
      'team.seoKeywords': 'zespół Kodikolab, eksperci rozwoju oprogramowania, profesjonaliści IT, innowatorzy technologiczni',
      'team.schemaName': 'Zespół Rozwojowy Kodikolab',
      'team.schemaDescription': 'Ekspercki zespół rozwoju oprogramowania w Kodikolab, dostarczający innowacyjnych i wysokiej jakości rozwiązań',
    'footer.quickLinks.contact': 'Kontakt',
    'footer.contact.title': 'Skontaktuj się z nami',
      'footer.contact.email': 'info@kodikolab.com',
      'footer.contact.phone': '+48 123 456 789',
      "project.itjuniors.title": "ITJuniors: Platforma edukacyjna dla przyszłych programistów",
      "project.itjuniors.subtitle": "Innowacyjne rozwiązanie w zakresie e-learningu dla branży IT",
      "project.itjuniors.features.title": "Kluczowe funkcjonalności",
      "project.itjuniors.features.personalized.title": "Spersonalizowane ścieżki nauki",
      "project.itjuniors.features.personalized.description": "Dostosowane programy nauczania dla różnych poziomów umiejętności i celów zawodowych.",
      "project.itjuniors.features.interactive.title": "Interaktywne środowisko kodowania",
      "project.itjuniors.features.interactive.description": "Wbudowany edytor kodu z możliwością wykonywania zadań programistycznych w czasie rzeczywistym.",
      "project.itjuniors.features.mentoring.title": "Mentoring i wsparcie ekspertów",
      "project.itjuniors.features.mentoring.description": "Dostęp do doświadczonych programistów i mentorów branżowych.",
      "project.itjuniors.features.mobile.title": "Dostęp mobilny",
      "project.itjuniors.features.mobile.description": "Pełna funkcjonalność na urządzeniach mobilnych, umożliwiająca naukę w dowolnym miejscu i czasie.",
      "project.itjuniors.features.security.title": "Bezpieczeństwo i prywatność",
      "project.itjuniors.features.security.description": "Zaawansowane mechanizmy ochrony danych użytkowników i ich postępów w nauce.",
      "project.itjuniors.technologies.title": "Technologie",
      "project.itjuniors.technologies.dotnet": ".NET Core",
      "project.itjuniors.technologies.react": "React",
      "project.itjuniors.technologies.sqlserver": "SQL Server",
      "project.itjuniors.technologies.azure": "Azure Cloud Services",
      'home.seoTitle': 'Kodikolab - Pionierskie Rozwiązania Programistyczne dla Ery Cyfrowej',
      'home.seoDescription': 'Kodikolab oferuje najnowocześniejsze usługi rozwoju oprogramowania, w tym aplikacje internetowe i mobilne, rozwiązania chmurowe i automatyzację.',
      'home.seoKeywords': 'rozwój oprogramowania, aplikacje internetowe, aplikacje mobilne, rozwiązania chmurowe, automatyzacja, Kodikolab',
      'home.schemaDescription': 'Kodikolab - Innowacyjne rozwiązania programistyczne dla ery cyfrowej',
      'projects.seoTitle': 'Nasze Projekty - Rozwiązania Programistyczne Kodikolab',
      'projects.seoDescription': 'Odkryj portfolio innowacyjnych projektów programistycznych Kodikolab, prezentujących nasze doświadczenie w rozwoju aplikacji internetowych, mobilnych i chmurowych.',
      'projects.seoKeywords': 'projekty programistyczne, rozwój stron internetowych, aplikacje mobilne, rozwiązania chmurowe, portfolio Kodikolab',
      'projects.schemaName': 'Projekty Kodikolab',
      'projects.schemaDescription': 'Kolekcja innowacyjnych projektów programistycznych stworzonych przez Kodikolab',
      'about.seoTitle': 'O Kodikolab - Nasza Historia, Misja i Wartości',
      'about.seoDescription': 'Poznaj historię Kodikolab, naszą misję dostarczania innowacyjnych rozwiązań programistycznych i wartości, które napędzają nasz sukces.',
      'about.seoKeywords': 'Kodikolab, firma programistyczna, nasza historia, misja, wartości, zespół',
      'about.schemaName': 'O Kodikolab',
      'about.schemaDescription': 'Informacje o Kodikolab, naszej misji, wartościach i zespole',
      'contact.seoTitle': 'Kontakt z Kodikolab - Skontaktuj się w sprawie Rozwiązań Programistycznych',
      'contact.seoDescription': 'Skontaktuj się z Kodikolab w sprawie innowacyjnych rozwiązań programistycznych. Napisz do nas, aby uzyskać konsultację, wycenę lub omówić potrzeby Twojego projektu.',
      'contact.seoKeywords': 'kontakt Kodikolab, zapytanie o rozwój oprogramowania, konsultacja projektu',
      'contact.schemaName': 'Kontakt z Kodikolab',
      'contact.schemaDescription': 'Informacje kontaktowe i formularz do skontaktowania się z Kodikolab',
      'services.seoTitle': 'Nasze Usługi - Rozwiązania Programistyczne Kodikolab',
      'services.seoDescription': 'Odkryj kompleksowe usługi rozwoju oprogramowania Kodikolab, w tym tworzenie aplikacji internetowych i mobilnych, rozwiązania chmurowe i wiele więcej.',
      'services.seoKeywords': 'usługi rozwoju oprogramowania, tworzenie stron internetowych, aplikacje mobilne, rozwiązania chmurowe, Kodikolab',
      'services.schemaName': 'Usługi Kodikolab',
      'services.schemaDescription': 'Przegląd usług rozwoju oprogramowania oferowanych przez Kodikolab',
      "project.itjuniors.timeline.title": "Harmonogram projektu",
      "project.itjuniors.timeline.concept.title": "Koncepcja i analiza wymagań",
      "project.itjuniors.timeline.concept.description": "Zdefiniowanie celów projektu, analiza potrzeb użytkowników i określenie głównych funkcjonalności platformy.",
      "project.itjuniors.timeline.concept.date": "Maj 2023",
      "project.itjuniors.timeline.uxui.title": "Projektowanie UX/UI",
      "project.itjuniors.timeline.uxui.description": "Stworzenie intuicyjnego interfejsu użytkownika i opracowanie ścieżek użytkownika dla różnych grup docelowych.",
      "project.itjuniors.timeline.uxui.date": "Czerwiec - Lipiec 2023",
      "project.itjuniors.timeline.backend.title": "Rozwój backend",
      "project.itjuniors.timeline.backend.description": "Implementacja logiki biznesowej, integracja bazy danych i tworzenie API z wykorzystaniem .NET Core.",
      "project.itjuniors.timeline.backend.date": "Sierpień - Wrzesień 2023",
      "project.itjuniors.timeline.frontend.title": "Rozwój frontend",
      "project.itjuniors.timeline.frontend.description": "Budowa responsywnego interfejsu użytkownika z wykorzystaniem React i integracja z backendem.",
      "project.itjuniors.timeline.frontend.date": "Wrzesień - Październik 2023",
      "project.itjuniors.timeline.testing.title": "Testowanie i optymalizacja",
      "project.itjuniors.timeline.testing.description": "Przeprowadzenie testów funkcjonalnych, wydajnościowych i bezpieczeństwa. Optymalizacja kodu i infrastruktury.",
      "project.itjuniors.timeline.testing.date": "Listopad 2023",
      "project.itjuniors.timeline.softlaunch.title": "Soft launch",
      "project.itjuniors.timeline.softlaunch.description": "Udostępnienie platformy dla ograniczonej grupy użytkowników w celu zebrania opinii i dalszych ulepszeń.",
      "project.itjuniors.timeline.softlaunch.date": "Grudzień 2023",
      "project.itjuniors.timeline.launch.title": "Oficjalne uruchomienie",
      "project.itjuniors.timeline.launch.description": "Pełne uruchomienie platformy ITJuniors dla wszystkich użytkowników.",
      "project.itjuniors.timeline.launch.date": "Marzec 2024",
      "project.itjuniors.cta.title": "Zrealizuj swój projekt z KodikoLab",
      "project.itjuniors.cta.description": "Jesteś zainteresowany stworzeniem innowacyjnej platformy edukacyjnej lub innego zaawansowanego rozwiązania technologicznego? Nasz zespół ekspertów w KodikoLab jest gotowy, aby pomóc Ci przekształcić Twoją wizję w rzeczywistość. Skontaktuj się z nami, aby omówić Twój projekt i rozpocząć współpracę.",
      "project.itjuniors.cta.button": "Skontaktuj się z nami",
    'footer.contact.address': 'ul. Technologiczna 123, 00-000 Warszawa',
    'footer.copyright': '© {year} Kodikolab. Wszelkie prawa zastrzeżone.',
    'projects.pageTitle': 'Nasze Projekty',
    'projects.searchPlaceholder': 'Szukaj projektów...',
    'projects.filterButton': 'Filtruj',
    'projects.loading': 'Ładowanie projektów',
    'projects.statusLabel': 'Status',
    'projects.clientLabel': 'Klient',
    'projects.fetchError': 'Nie udało się pobrać projektów',
    'projects.loadError': 'Nie udało się załadować projektów. Wyświetlanie przykładowych danych.',
    'projects.filter.all': 'Wszystkie',
    'projects.status.completed': 'Zakończony',
    'projects.status.planning': 'Planowanie',
    'projects.client.techInnovators': 'Tech Innovators Sp. z o.o.',
    'projects.client.globalSolutions': 'Global Solutions Sp. z o.o.',
      'projects.client.futureSystems': 'Future Systems S.A.',
      'project.itjuniors.name': 'ITJuniors',
      'project.itjuniors.description': 'Innowacyjna platforma edukacyjna dla dzieci w wieku 7-11 lat do nauki programowania poprzez Minecraft Education Edition.',
      'project.stages.concept': 'Koncepcja',
      'project.stages.concept.description': 'Opracowanie głównych założeń projektu i analiza potrzeb edukacyjnych młodych uczniów.',
      'project.stages.planning': 'Planowanie',
      'project.stages.planning.description': 'Tworzenie szczegółowego planu projektu, programu nauczania i celów edukacyjnych.',
      'project.stages.design': 'Projektowanie UX/UI',
      'project.stages.design.description': 'Projektowanie przyjaznych dla dzieci interfejsów i środowisk nauczania opartych na Minecraft.',
      'project.stages.development': 'Rozwój',
      'project.itjuniors.seo.title': 'ITJuniors: Platforma Edukacyjna dla Przyszłych Programistów | Kodikolab',
      'project.itjuniors.seo.description': 'Odkryj ITJuniors, innowacyjną platformę e-learningową stworzoną przez Kodikolab, zaprojektowaną, aby wspierać początkujących programistów w ich drodze do sukcesu w branży IT.',
      'project.itjuniors.seo.keywords': 'ITJuniors, platforma edukacyjna, programowanie, e-learning, Kodikolab, branża IT',

      'project.smartcity.seo.title': 'SmartCity App: Kompleksowe Rozwiązanie do Zarządzania Miastem | Kodikolab',
      'project.smartcity.seo.description': 'Poznaj naszą aplikację SmartCity, kompleksową aplikację mobilną do inteligentnego zarządzania miastem, integrującą urządzenia IoT i analizę danych w czasie rzeczywistym.',
      'project.smartcity.seo.keywords': 'SmartCity App, zarządzanie miastem, IoT, analiza danych, Kodikolab, inteligentne miasto',
      'project.stages.development.description': 'Implementacja platformy i integracja z Minecraft Education Edition.',
      'project.stages.testing': 'Testowanie',
      'project.stages.testing.description': 'Przeprowadzenie dokładnych testów z grupami fokusowymi dzieci i edukatorów.',
      'project.stages.launch': 'Uruchomienie',
      'project.stages.launch.description': 'Oficjalne uruchomienie platformy ITJuniors.',
      'project.stages.planned.from': 'Planowane od {date}',
      'project.stages.planned.for': 'Planowane na {date}',
      'project.keyFeatures': 'Kluczowe Funkcje',
      'project.features.minecraft': 'Nauka poprzez Minecraft Education Edition',
      'project.features.ageAppropriate': 'Treści dostosowane do wieku 7-11 lat',
      'project.features.interactiveLessons': 'Interaktywne lekcje kodowania w świecie Minecraft',
      'project.features.progressTracking': 'Grywalizowany system śledzenia postępów',
      'project.technologies': 'Technologie',
      'project.progress': 'Postęp Projektu',
      'project.faq': 'FAQ',
      'project.faq.launch': 'Kiedy ITJuniors będzie dostępne?',
      'project.faq.launch.answer': 'Planujemy uruchomienie ITJuniors w trzecim kwartale 2024 roku.',
      'project.faq.age': 'Czy ITJuniors jest odpowiednie dla dzieci spoza przedziału wiekowego 7-11 lat?',
      'project.faq.age.answer': 'Treści są specjalnie zaprojektowane dla dzieci w wieku 7-11 lat, ale rozważamy rozszerzenie oferty dla różnych grup wiekowych w przyszłości.',
      'loading': 'Ładowanie...',
    'projects.client.digitalDynamics': 'Digital Dynamics Sp. j.',
      'projects.client.ecoTech': 'EcoTech Solutions Sp. z o.o.',
      'services.ourProcess': 'Nasz Proces Rozwoju',
    'projects.client.smartInnovations': 'Smart Innovations S.A.',
    'projects.type.webDev': 'Rozwój Stron Internetowych',
    'projects.type.mobileApp': 'Aplikacja Mobilna',
    'projects.type.cloudSolution': 'Rozwiązanie Chmurowe',
    'projects.type.aiIntegration': 'Integracja AI',
    'projects.type.iotPlatform': 'Platforma IoT',
    'projects.type.blockchain': 'Implementacja Blockchain',
    'projects.description': 'Innowacyjny projekt {type} wykorzystujący najnowsze technologie do rozwiązywania złożonych wyzwań biznesowych. Ten projekt ma na celu zrewolucjonizowanie branży dzięki unikalnemu podejściu i zaawansowanym funkcjom.',
    'services.pageTitle': 'Nasze Usługi',
    'services.net.title': 'Rozwój .NET',
    'services.net.description': 'Ekspercki rozwój .NET dla solidnych, skalowalnych aplikacji',
    'services.react.title': 'Rozwój React',
    'services.react.description': 'Tworzenie dynamicznych i responsywnych interfejsów użytkownika z React',
    'services.angular.title': 'Rozwój Angular',
    'services.angular.description': 'Budowanie zaawansowanych aplikacji jednostronicowych z Angular',
    'services.database.title': 'Projektowanie Baz Danych',
    'services.database.description': 'Wydajne i bezpieczne rozwiązania bazodanowe dla Twoich potrzeb',
    'services.backend.title': 'Rozwój Backend',
    'services.backend.description': 'Solidne architektury backendowe do zasilania Twoich aplikacji',
    'services.wordpress.title': 'Rozwój WordPress',
    'services.wordpress.description': 'Niestandardowe motywy i wtyczki WordPress dla Twoich unikalnych potrzeb',
    'services.custom.title': 'Rozwiązania Niestandardowe',
    'services.custom.description': 'Dostosowane rozwiązania programowe spełniające Twoje unikalne wymagania',
    'about.pageTitle': 'O KodikoLab',
    'about.story.title': 'Nasza Historia',
    'about.story.paragraph1': 'Założona w październiku 2024 roku, firma KodikoLab powstała z pasji do tworzenia wyjątkowych rozwiązań cyfrowych. Nasz zespół doświadczonych programistów i kreatywnych umysłów połączył się ze wspólną wizją: przekształcania pomysłów w wydajne, innowacyjne aplikacje.',
    'about.story.paragraph2': 'Od początku naszej działalności dążymy do podnoszenia standardów rozwoju oprogramowania. Wierzymy, że dobry kod to nie tylko funkcjonalność - to tworzenie eleganckich rozwiązań, które napędzają rozwój biznesu i satysfakcję użytkowników.',
    'about.mission.title': 'Nasza Misja',
    'about.mission.paragraph1': 'Misją KodikoLab jest wzmacnianie firm poprzez transformacyjne rozwiązania cyfrowe. Dążymy do tworzenia oprogramowania, które nie tylko spełnia, ale przewyższa oczekiwania naszych klientów, wspierając innowacje i napędzając sukces w erze cyfrowej.',
    'about.mission.paragraph2': 'Jesteśmy zaangażowani w dostarczanie doskonałości w każdej linii kodu, każdym interfejsie użytkownika i każdym tworzonym przez nas doświadczeniu cyfrowym. Naszym celem jest bycie na czele postępu technologicznego, ciągłe przesuwanie granic możliwości w rozwoju oprogramowania.',
    'about.values.title': 'Nasze Wartości',
    'about.values.description': 'Sercem KodikoLab są wartości, które kierują naszą pracą i kształtują naszą kulturę. Te zasady są fundamentem naszego sukcesu i siłą napędową naszego zaangażowania w doskonałość.',
    'about.values.codeExcellence.title': 'Doskonałość Kodu',
    'about.values.codeExcellence.description': 'Jesteśmy zaangażowani w pisanie czystego, wydajnego i łatwego w utrzymaniu kodu, który przetrwa próbę czasu.',
    'about.values.innovation.title': 'Innowacja',
    'about.values.innovation.description': 'Stale badamy nowe technologie i metodologie, aby dostarczać najnowocześniejsze rozwiązania.',
    'about.values.clientPartnership.title': 'Partnerstwo z Klientem',
    'about.values.clientPartnership.description': 'Wierzymy w budowanie silnych, długotrwałych relacji z naszymi klientami, stając się prawdziwymi partnerami w ich sukcesie.',
    'about.values.continuousGrowth.title': 'Ciągły Rozwój',
    'about.values.continuousGrowth.description': 'Jesteśmy oddani ciągłemu uczeniu się i doskonaleniu, pozostając na czele w szybko zmieniającym się świecie technologii.',
    'about.team.title': 'Nasz Zespół',
    'about.team.description1': 'KodikoLab jest napędzane przez zespół pełnych pasji i doświadczonych profesjonalistów. Z latami zbiorowego doświadczenia w różnych obszarach rozwoju oprogramowania, nasi eksperci wnoszą bogactwo wiedzy i kreatywności do każdego projektu.',
    'about.team.description2': 'Od doświadczonych programistów .NET po specjalistów React i Angular, od architektów baz danych po projektantów UX/UI, nasz zespół jest przygotowany do obsługi projektów o każdej skali i złożoności. Jesteśmy dumni z naszego podejścia opartego na współpracy, łącząc nasze indywidualne mocne strony, aby dostarczać kompleksowe rozwiązania, które przynoszą realne wyniki.',
    'about.expertise.title': 'Nasza Ekspertyza',
    'about.expertise.introduction': 'W KodikoLab specjalizujemy się w szerokim zakresie technologii i usług, w tym:',
    'about.expertise.list': 'Rozwój .NET, Rozwiązania frontendowe React i Angular, Projektowanie i optymalizacja baz danych, Tworzenie oprogramowania na zamówienie, Tworzenie aplikacji internetowych i mobilnych, Rozwiązania i integracja chmury',
    'about.expertise.conclusion': 'Nasza wszechstronność pozwala nam zaspokoić różnorodne potrzeby klientów, od startupów poszukujących rozwoju MVP po duże przedsiębiorstwa wymagające złożonych, skalowalnych rozwiązań.',
    'about.commitment.title': 'Nasze Zobowiązanie',
    'about.commitment.paragraph1': 'W KodikoLab jesteśmy czymś więcej niż tylko firmą zajmującą się tworzeniem oprogramowania - jesteśmy Twoim partnerem technologicznym. Jesteśmy zaangażowani w zrozumienie Twoich unikalnych wyzwań i celów oraz dostarczanie rozwiązań, które nie tylko spełniają Twoje obecne potrzeby, ale także przygotowują Cię na przyszły sukces.',
    'about.commitment.paragraph2': 'Niezależnie od tego, czy jesteś małą firmą chcącą zaznaczyć swoją obecność w świecie cyfrowym, czy dużą korporacją dążącą do usprawnienia operacji poprzez niestandardowe oprogramowanie, KodikoLab jest tutaj, aby przekształcić Twoją wizję w rzeczywistość. Dołącz do nas w tej ekscytującej podróży cyfrowej transformacji i stwórzmy razem coś niezwykłego.',
      'contact.pageTitle': 'Skontaktuj się z nami',
      'project.features.title': 'Kluczowe Funkcje',
    'contact.infoTitle': 'Chcielibyśmy usłyszeć od Ciebie',
    'contact.infoDescription': 'Gotowy na rozpoczęcie nowego projektu lub masz pytania dotyczące naszych usług? Skontaktuj się z nami i stwórzmy razem coś niesamowitego!',
    'contact.email': 'info@kodikolab.com',
    'contact.phone': '+48 555 123 456',
    'contact.address': 'ul. Technologiczna 123, 00-001 Warszawa',
    'contact.form.name': 'Imię i nazwisko',
    'contact.form.email': 'Email',
    'contact.form.phone': 'Numer telefonu',
    'contact.form.subject': 'Temat',
    'contact.form.message': 'Wiadomość',
    'contact.form.send': 'Wyślij wiadomość',
    'contact.form.sending': 'Wysyłanie...',
    'contact.form.successMessage': 'Dziękujemy za Twoją wiadomość! Odezwiemy się wkrótce.',
      'contact.form.errorMessage': 'Ups! Coś poszło nie tak. Proszę spróbować ponownie później.',
      'nav.team': 'Zespół',
      'nav.workProcess': 'Proces pracy',
      'team.pageTitle': 'Nasz Zespół',
      'team.pageDescription': 'Poznaj ekspertów stojących za innowacyjnymi rozwiązaniami Kodikolab.',
      'team.pageKeywords': 'zespół, eksperci, programiści, projektanci, Kodikolab',
      'services.ourExpertise': 'Nasza Ekspertyza',
      'services.individualApproach': 'Indywidualne Podejście',
      'services.approachDescription': 'W Kodikolab wierzymy, że każdy projekt jest unikalny. Nasze rozwiązania są dopasowane do specyficznych potrzeb Twojego biznesu.',
      'services.benefits.tailored': 'Rozwiązania szyte na miarę',
      'services.benefits.expertise': 'Wieloletnie doświadczenie w branży',
      'services.benefits.quality': 'Najwyższa jakość kodu',
      'services.benefits.support': 'Wsparcie 24/7',
      'services.calculator.description': 'Nasz kalkulator pomoże Ci oszacować koszty projektu. Pamiętaj, że każdy projekt traktujemy indywidualnie i ostateczna wycena może się różnić.',
      'workProcess.consultation.title': 'Konsultacja',
      'workProcess.consultation.shortDescription': 'Zrozumienie Twoich potrzeb',
      'workProcess.planning.title': 'Planowanie',
      'workProcess.planning.shortDescription': 'Tworzenie mapy drogowej',
      'workProcess.development.title': 'Rozwój',
      'workProcess.seoTitle': 'Nasz Proces Pracy - Metodologia Rozwoju Oprogramowania Kodikolab',
      'workProcess.seoDescription': 'Poznaj efektywny i skuteczny proces rozwoju oprogramowania Kodikolab, od konsultacji po ciągłe doskonalenie.',
      'workProcess.seoKeywords': 'proces pracy, metodologia rozwoju oprogramowania, Kodikolab, zwinny rozwój',
      'workProcess.schemaName': 'Proces Pracy Kodikolab',
      'workProcess.schemaDescription': 'Szczegółowy przegląd procesu i metodologii rozwoju oprogramowania Kodikolab',
      'workProcess.testing.title': 'Testowanie',
      'workProcess.testing.shortDescription': 'Zapewnienie jakości',
      'workProcess.deployment.title': 'Wdrożenie',
      'workProcess.deployment.shortDescription': 'Uruchomienie',
      'workProcess.maintenance.title': 'Utrzymanie',
      'workProcess.maintenance.shortDescription': 'Ciągłe wsparcie',
      'services.showDetails': 'Pokaż Szczegóły',
      'services.hideDetails': 'Ukryj Szczegóły',
      'services.benefits.innovation': 'Nowatorskie innowacje',
      'services.benefits.costEffective': 'Efektywne kosztowo rozwiązania',
      'services.customDevelopment.title': 'Tworzenie Oprogramowania na Zamówienie',
      'services.customDevelopment.description': 'Dostosowane rozwiązania programowe spełniające unikalne potrzeby biznesowe',
      'services.customDevelopment.details': 'Tworzymy aplikacje szyte na miarę, które idealnie odpowiadają procesom i celom Twojej firmy.',
      'services.frontendDevelopment.title': 'Rozwój Frontend',
      'services.frontendDevelopment.description': 'Tworzenie angażujących i responsywnych interfejsów użytkownika',
      'services.frontendDevelopment.details': 'Nasi eksperci frontend tworzą intuicyjne i atrakcyjne wizualnie interfejsy, które poprawiają doświadczenie użytkownika.',
      'services.backendDevelopment.title': 'Rozwój Backend',
      'services.backendDevelopment.description': 'Budowanie solidnych i skalowalnych aplikacji po stronie serwera',
      'services.backendDevelopment.details': 'Rozwijamy wydajne systemy backend, które zapewniają płynne przetwarzanie danych i wydajność aplikacji.',
      'services.mobileDevelopment.title': 'Tworzenie Aplikacji Mobilnych',
      'services.mobileDevelopment.description': 'Rozwój natywnych i wieloplatformowych aplikacji mobilnych',
      'services.mobileDevelopment.details': 'Od iOS po Androida, tworzymy aplikacje mobilne, które zapewniają wyjątkowe doświadczenia użytkownika na wszystkich urządzeniach.',
      'services.databaseDesign.title': 'Projektowanie Baz Danych',
      'services.databaseDesign.description': 'Projektowanie wydajnych i bezpiecznych rozwiązań bazodanowych',
      'services.databaseDesign.details': 'Projektujemy systemy bazodanowe, które zapewniają integralność danych, bezpieczeństwo i optymalną wydajność.',
      'services.cloudServices.title': 'Usługi Chmurowe',
      'services.cloudServices.description': 'Wykorzystanie technologii chmurowych dla skalowalnych rozwiązań',
      'services.cloudServices.details': 'Pomagamy firmom wykorzystać moc chmury obliczeniowej dla zwiększenia elastyczności i efektywności kosztowej.',
      'services.containerization.title': 'Konteneryzacja',
      'services.containerization.description': 'Wdrażanie rozwiązań opartych na kontenerach dla usprawnienia deploymentu',
      'services.containerization.details': 'Wykorzystujemy Docker i Kubernetes do usprawnienia wdrażania i skalowania aplikacji.',
      'services.security.title': 'Cyberbezpieczeństwo',
      'services.security.description': 'Wdrażanie solidnych środków bezpieczeństwa dla ochrony zasobów cyfrowych',
      'services.security.details': 'Nasi eksperci ds. bezpieczeństwa dbają o ochronę Twoich aplikacji i danych przed cyberzagrożeniami.',
      'services.maintenance.title': 'Utrzymanie i Wsparcie',
      'services.maintenance.description': 'Zapewnienie ciągłego wsparcia i aktualizacji dla Twojego oprogramowania',
      'services.maintenance.details': 'Oferujemy kompleksowe usługi utrzymania, aby Twoje oprogramowanie działało sprawnie i było zawsze aktualne.',
      'services.performanceOptimization.title': 'Optymalizacja Wydajności',
      'services.performanceOptimization.description': 'Poprawa wydajności oprogramowania dla lepszej efektywności',
      'services.performanceOptimization.details': 'Dostrajamy Twoje aplikacje, aby osiągnąć optymalną szybkość i wykorzystanie zasobów.',
      'services.devops.title': 'Usługi DevOps',
      'services.devops.description': 'Wdrażanie praktyk DevOps dla usprawnienia procesów rozwoju',
      'services.devops.details': 'Integrujemy metodologie DevOps, aby usprawnić procesy rozwoju, testowania i wdrażania.',
      'services.consultancy.title': 'Doradztwo IT',
      'services.consultancy.description': 'Zapewnienie eksperckiego doradztwa w zakresie strategii technologicznych',
      'services.consultancy.details': 'Nasi konsultanci pomagają podejmować świadome decyzje dotyczące infrastruktury IT i inwestycji w oprogramowanie.',
      'services.analytics.title': 'Analityka Danych',
      'services.analytics.description': 'Przekształcanie danych w praktyczne spostrzeżenia',
      'services.analytics.details': 'Wdrażamy rozwiązania analityki danych, aby pomóc w podejmowaniu decyzji opartych na danych i zdobyciu przewagi konkurencyjnej.',
      'services.calculator.apiDevelopment': 'Rozwój API',
      'services.calculator.react': 'React',
      'services.calculator.angular': 'Angular',
      'services.calculator.vue': 'Vue.js',
      'services.calculator.native': 'Native',
      'services.calculator.flutter': 'Flutter',
      'services.calculator.mysql': 'MySQL',
      'services.calculator.postgresql': 'PostgreSQL',
      'services.calculator.mongodb': 'MongoDB',
      'services.calculator.redis': 'Redis',
      'services.calculator.elasticsearch': 'Elasticsearch',
      'services.calculator.selfHosted': 'Self-hosted',
      'services.calculator.kodikolabHosting': 'Hosting Kodikolab',
      'services.calculator.aws': 'Amazon Web Services (AWS)',
      'services.calculator.gcp': 'Google Cloud Platform (GCP)',
      'services.calculator.azure': 'Microsoft Azure',
      'services.calculator.security': 'Bezpieczeństwo',
      'services.calculator.testing': 'Testowanie',
      'services.calculator.optimization': 'Optymalizacja',
      'services.calculator.monitoring': 'Monitoring',
      'services.calculator.devops': 'DevOps',
      'services.calculator.analytics': 'Analityka',
      'services.calculator.consultancy': 'Doradztwo',
      'services.calculator.additionalServices': 'Dodatkowe Usługi',
      'services.calculator.developmentCost': 'Koszt Rozwoju: {cost} zł',
      'services.calculator.deploymentCost': 'Koszt Wdrożenia: {cost} zł',
      'services.calculator.additionalServicesCost': 'Koszt Dodatkowych Usług: {cost} zł',
      'services.calculator.totalCost': 'Całkowity Szacowany Koszt: {cost} zł',
      'notFound.title': 'Strona nie znaleziona',
      'notFound.message': 'Przepraszamy, ale strona, której szukasz, nie istnieje.',
      'notFound.linkText': 'Wróć do strony głównej',
      'workProcess.introduction.paragraph1': 'W Kodikolab wierzymy, że skuteczny proces pracy jest kluczem do sukcesu każdego projektu. Nasz sprawdzony proces łączy w sobie elastyczność z precyzją, zapewniając optymalne rezultaty dla naszych klientów.',
      'workProcess.introduction.paragraph2': 'Od pierwszej konsultacji do ciągłej optymalizacji, każdy etap naszego procesu jest starannie zaprojektowany, aby zapewnić najwyższą jakość, efektywność i satysfakcję klienta.',
      'workProcess.benefits.title': 'Korzyści z Naszego Podejścia',
      'workProcess.benefits.description': 'Nasze unikalne podejście do procesu pracy przynosi naszym klientom wiele korzyści:',
      'workProcess.benefits.list': 'Przejrzystość i przewidywalność, Szybsze dostarczanie wartości, Elastyczność w dostosowywaniu się do zmian, Wysoka jakość końcowego produktu, Optymalizacja kosztów i czasu, Ciągłe doskonalenie',
      'workProcess.consultation.details': 'Na etapie konsultacji skupiamy się na dogłębnym zrozumieniu Twoich celów biznesowych, wymagań projektu i oczekiwań. Nasi eksperci analizują Twoje potrzeby i proponują najlepsze rozwiązania technologiczne.',
      'workProcess.consultation.benefits': 'Precyzyjne zrozumienie potrzeb, Dopasowane rozwiązania, Realistyczne oczekiwania',
      'workProcess.planning.details': 'Tworzymy szczegółowy plan projektu, w tym harmonogramy, kamienie milowe i alokację zasobów. Ten etap stanowi fundament dla skutecznej realizacji projektu.',
      'workProcess.planning.benefits': 'Jasna wizja projektu, Efektywna alokacja zasobów, Minimalizacja ryzyka',
      'workProcess.development.shortDescription': 'Ożywianie pomysłów',
      'workProcess.development.details': 'Nasz zespół ekspertów przekształca plany w rzeczywistość, pisząc czysty, wydajny kod. Stosujemy najlepsze praktyki i standardy branżowe, aby zapewnić wysokiej jakości, skalowalne rozwiązania.',
      'workProcess.development.benefits': 'Wysokiej jakości kod, Skalowalne rozwiązania, Efektywny proces rozwoju',
      'workProcess.testing.details': 'Przeprowadzamy dokładne testy, aby upewnić się, że Twoje rozwiązanie spełnia najwyższe standardy jakości. Obejmuje to testy jednostkowe, integracyjne i testy akceptacyjne użytkownika.',
      'workProcess.testing.benefits': 'Oprogramowanie bez błędów, Ulepszone doświadczenie użytkownika, Zmniejszona liczba problemów po wdrożeniu',
      'workProcess.deployment.details': 'Ostrożnie uruchamiamy Twoje rozwiązanie, zapewniając płynne przejście do produkcji. Nasz proces wdrożenia jest zaprojektowany tak, aby zminimalizować przestoje i zmaksymalizować niezawodność.',
      'workProcess.deployment.benefits': 'Płynne przejście do produkcji, Minimalne zakłócenia, Niezawodne uruchomienie',
      'workProcess.maintenance.details': 'Zapewniamy ciągłe wsparcie i aktualizacje, aby Twoje rozwiązanie działało sprawnie. Nasze usługi utrzymania obejmują naprawę błędów, aktualizacje bezpieczeństwa i monitorowanie wydajności.',
      'workProcess.maintenance.benefits': 'Stała wydajność, Aktualne zabezpieczenia, Spokój ducha',
      'workProcess.optimization.title': 'Optymalizacja',
      'workProcess.optimization.shortDescription': 'Ciągłe ulepszanie',
      'workProcess.optimization.details': 'Po wdrożeniu, nieustannie monitorujemy wydajność i zbieramy dane, aby identyfikować obszary do optymalizacji. Wprowadzamy ulepszenia, które zwiększają wydajność, skalowalność i doświadczenie użytkownika.',
      'workProcess.optimization.benefits': 'Zwiększona wydajność, Lepsza skalowalność, Optymalizacja kosztów',
      'workProcess.feedback.title': 'Feedback i Iteracja',
      'services.expertiseDescription': 'Nasz zespół ekspertów specjalizuje się w dostarczaniu najnowocześniejszych rozwiązań programistycznych dostosowanych do unikalnych potrzeb Twojego biznesu. Dzięki wieloletniemu doświadczeniu w różnych branżach, wprowadzamy innowacyjne podejście do każdego projektu.',
      'services.processDescription': 'Nasz sprawdzony proces rozwoju zapewnia wysoką jakość wyników i płynną współpracę. Od wstępnej konsultacji do końcowego wdrożenia, angażujemy Cię na każdym etapie.',
      'services.process.step1': 'Konsultacja i Planowanie',
      'services.process.step2': 'Projektowanie i Rozwój',
      'services.process.step3': 'Testowanie i Zapewnienie Jakości',
      'services.process.step4': 'Wdrożenie i Wsparcie',
      'services.readyToStart': 'Gotowy, by Rozpocząć Swój Projekt?',
      'services.ctaDescription': 'Zamieńmy Twoje pomysły w rzeczywistość. Skontaktuj się z nami już dziś, aby uzyskać bezpłatną konsultację i zobacz, jak możemy pomóc w rozwoju Twojego biznesu.',
      'services.contactUs': 'Skontaktuj się z Nami',
      'services.viewPortfolio': 'Zobacz Nasze Portfolio',
      'services.faq': 'Często Zadawane Pytania',
      'services.faq1.question': 'Jakie rodzaje projektów obsługujecie?',
      'services.faq1.answer': 'Obsługujemy szeroki zakres projektów, od małych stron internetowych dla firm po złożone rozwiązania oprogramowania dla przedsiębiorstw. Nasza ekspertyza obejmuje rozwój stron internetowych, aplikacje mobilne, niestandardowe oprogramowanie i wiele więcej.',
      'services.faq2.question': 'Jak długo trwa typowy projekt?',
      'services.faq2.answer': 'Czas trwania projektu zależy od jego złożoności i zakresu. Prosta strona internetowa może zająć 4-6 tygodni, podczas gdy złożone rozwiązanie programowe może trwać kilka miesięcy. Dostarczymy szczegółowy harmonogram podczas wstępnej konsultacji.',
      'services.faq3.question': 'Czy zapewniacie ciągłe wsparcie po zakończeniu projektu?',
      'services.faq3.answer': 'Tak, oferujemy usługi ciągłego wsparcia i konserwacji, aby zapewnić optymalne działanie Twojego oprogramowania. Możemy dostosować pakiet wsparcia do Twoich konkretnych potrzeb.',
      'workProcess.feedback.shortDescription': 'Ciągłe doskonalenie',
      'workProcess.feedback.details': 'Regularnie zbieramy feedback od użytkowników i klientów. Te cenne informacje są wykorzystywane do ciągłego doskonalenia produktu i planowania przyszłych funkcji.',
      'workProcess.feedback.benefits': 'Produkt dostosowany do potrzeb użytkowników, Ciągłe ulepszenia, Długoterminowa wartość'
  },
  [LOCALES.GERMAN]: {
    'app.title': 'Kodikolab',
    'nav.home': 'Startseite',
    'nav.projects': 'Projekte',
    'nav.services': 'Dienstleistungen',
    'nav.about': 'Über uns',
    'nav.contact': 'Kontakt',
    'banner.title': 'Verwirklichen Sie Ihre Vision mit {company}',
    'banner.subtitle': 'Zukunftsweisende Softwarelösungen für das digitale Zeitalter',
    'banner.cta': 'Entfachen Sie Ihre Innovation',
    'feature.software': 'Softwareentwicklung',
    'feature.mobile': 'Mobile Anwendungen',
    'feature.cloud': 'Cloud-Lösungen',
    'feature.automation': 'Automatisierung',
    'stats.clients': 'Zufriedene Kunden',
    'stats.projects': 'Abgeschlossene Projekte',
    'stats.satisfaction': 'Kundenzufriedenheit',
    'why.sectionTitle': 'Warum Kodikolab wählen?',
    'why.expertTeam.title': 'Expertenteam',
    'why.expertTeam.description': 'Qualifizierte Fachleute, die sich Ihrem Erfolg verschrieben haben',
    'why.innovativeSolutions.title': 'Innovative Lösungen',
    'why.innovativeSolutions.description': 'Modernste Technologie für aktuelle Herausforderungen',
    'why.clientFocused.title': 'Kundenorientierter Ansatz',
    'why.clientFocused.description': 'Ihre Vision, unsere Mission',
    'why.leadingTechnology.title': 'Führende Technologie',
    'why.leadingTechnology.description': 'Bleiben Sie mit den neuesten technologischen Fortschritten an der Spitze',
    'projects.sectionTitle': 'Aktuelle Projekte',
    'projects.random.name': 'Innovative Technologielösung',
    'projects.random.description': 'Ein hochmodernes Projekt, das die neuesten Technologien nutzt, um komplexe Geschäftsherausforderungen zu lösen.',
    'projects.status.inProgress': 'In Bearbeitung',
    'projects.random.client': 'Tech Innovatoren GmbH',
    'projects.imageAlt': 'Projekt',
    'projects.status': 'Status',
      'projects.client': 'Kunde',
      'team.pageSubtitle': 'Eine Gruppe leidenschaftlicher Experten, die sich der Umsetzung Ihrer Ideen in die Realität widmet',
      'team.seniorDeveloper': 'Senior Entwickler',
      'team.seniorDeveloperDesc': 'Entwickelt robuste und skalierbare Softwarelösungen für verschiedene Plattformen.',
      'team.qaEngineer': 'QA Ingenieur',
      'team.qaEngineerDesc': 'Gewährleistet einwandfreie Qualität durch rigorose Test- und Qualitätssicherungsprozesse.',
      'team.devOpsEngineer': 'DevOps Ingenieur',
      'team.devOpsEngineerDesc': 'Optimiert unsere Entwicklungspipeline und Infrastruktur für Höchstleistungen.',
      'team.uiDesigner': 'UI/UX Designer',
      'team.uiDesignerDesc': 'Erstellt schöne, intuitive Benutzeroberflächen, die Benutzer begeistern und Engagement fördern.',
      'team.productManager': 'Produktmanager',
      'team.productManagerDesc': 'Lenkt unsere Produktstrategie, um maximalen Wert zu liefern und Markttrends vorauszusein.',
      'team.innovationLead': 'Innovationsleiter',
      'team.innovationLeadDesc': 'Verschiebt Grenzen mit modernsten Technologien, um uns an der Spitze der Branche zu halten.',
      'team.strengthTitle': 'Unsere Teamstärken',
      'team.strength.collaboration': 'Nahtlose Zusammenarbeit',
      'team.strength.collaborationDesc': 'Wir arbeiten nahtlos zusammen, um herausragende Ergebnisse zu liefern.',
      'team.strength.innovation': 'Innovationsgetrieben',
      'team.strength.innovationDesc': 'Wir erkunden ständig neue Ideen und Technologien, um einen Schritt voraus zu sein.',
      'team.strength.expertise': 'Tiefgreifende Expertise',
      'team.strength.expertiseDesc': 'Jahre der Erfahrung in verschiedenen Technologiebereichen.',
      'team.strength.results': 'Ergebnisorientiert',
      'team.strength.resultsDesc': 'Fokussiert darauf, unseren Kunden greifbaren Mehrwert zu liefern.',
      'team.ctaButton': 'Arbeiten Sie mit uns',
    'projects.viewAll': 'Alle Projekte ansehen',
    'news.sectionTitle': 'Aktuelle Nachrichten & Kommende Veranstaltungen',
    'news.ai.title': 'Kodikolab startet neuen KI-Dienst',
    'news.ai.description': 'Revolutionierung von Geschäftsprozessen mit modernsten KI-Lösungen',
    'news.webinar.title': 'Kommendes Webinar: Zukunft der Webentwicklung',
    'news.webinar.description': 'Nehmen Sie am 15. Juni teil und erhalten Sie Einblicke in neue Webtechnologien',
    'news.hiring.title': 'Wir stellen ein: Senior React Entwickler',
    'news.hiring.description': 'Werden Sie Teil unseres innovativen Teams und gestalten Sie die Zukunft von Webanwendungen',
    'news.readMore': 'Mehr Lesen',
    'footer.company': 'Kodikolab',
    'footer.slogan': 'Innovative Lösungen für Ihre digitalen Bedürfnisse.',
    'footer.social.facebook': 'Facebook',
      'footer.social.twitter': 'Twitter',
      'services.calculator.selectFramework': 'Framework auswählen',
      'services.calculator.selectDatabase': 'Datenbank auswählen',
      'services.calculator.selectDeployment': 'Bereitstellungsoption auswählen',
    'footer.social.linkedin': 'LinkedIn',
      'footer.social.instagram': 'Instagram',
      "project.itjuniors.title": "ITJuniors: Bildungsplattform für zukünftige Programmierer",
      "project.itjuniors.subtitle": "Innovative E-Learning-Lösung für die IT-Branche",
      "project.itjuniors.features.title": "Hauptfunktionen",
      "project.itjuniors.features.personalized.title": "Personalisierte Lernpfade",
      "project.itjuniors.features.personalized.description": "Maßgeschneiderte Lehrpläne für verschiedene Qualifikationsniveaus und Karriereziele.",
      "project.itjuniors.features.interactive.title": "Interaktive Programmierumgebung",
      "project.itjuniors.features.interactive.description": "Integrierter Code-Editor mit Möglichkeit zur Echtzeit-Ausführung von Programmieraufgaben.",
      "project.itjuniors.features.mentoring.title": "Mentoring und Expertenunterstützung",
      "project.itjuniors.features.mentoring.description": "Zugang zu erfahrenen Programmierern und Branchenmentoren.",
      "project.itjuniors.features.mobile.title": "Mobiler Zugriff",
      "project.itjuniors.features.mobile.description": "Volle Funktionalität auf mobilen Geräten, ermöglicht Lernen jederzeit und überall.",
      "project.itjuniors.features.security.title": "Sicherheit und Datenschutz",
      "project.itjuniors.features.security.description": "Fortschrittliche Mechanismen zum Schutz von Benutzerdaten und Lernfortschritten.",
      "project.itjuniors.technologies.title": "Technologien",
      "project.itjuniors.technologies.dotnet": ".NET Core",
      "project.itjuniors.technologies.react": "React",
      "project.itjuniors.technologies.sqlserver": "SQL Server",
      "project.itjuniors.technologies.azure": "Azure Cloud Services",
      "project.itjuniors.timeline.title": "Projektzeitplan",
      "project.itjuniors.timeline.concept.title": "Konzept und Anforderungsanalyse",
      "project.itjuniors.timeline.concept.description": "Festlegung der Projektziele, Analyse der Benutzerbedürfnisse und Bestimmung der Hauptfunktionen der Plattform.",
      "project.itjuniors.timeline.concept.date": "Mai 2023",
      "project.itjuniors.timeline.uxui.title": "UX/UI-Design",
      "project.itjuniors.timeline.uxui.description": "Erstellung einer intuitiven Benutzeroberfläche und Entwicklung von Benutzerpfaden für verschiedene Zielgruppen.",
      "project.itjuniors.timeline.uxui.date": "Juni - Juli 2023",
      "project.itjuniors.timeline.backend.title": "Backend-Entwicklung",
      "project.itjuniors.timeline.backend.description": "Implementierung der Geschäftslogik, Datenbankintegration und Erstellung der API mit .NET Core.",
      "project.itjuniors.timeline.backend.date": "August - September 2023",
      "project.itjuniors.timeline.frontend.title": "Frontend-Entwicklung",
      "project.itjuniors.timeline.frontend.description": "Aufbau einer responsiven Benutzeroberfläche mit React und Integration mit dem Backend.",
      "project.itjuniors.timeline.frontend.date": "September - Oktober 2023",
      "project.itjuniors.timeline.testing.title": "Tests und Optimierung",
      "project.itjuniors.timeline.testing.description": "Durchführung von Funktions-, Leistungs- und Sicherheitstests. Optimierung von Code und Infrastruktur.",
      "project.itjuniors.timeline.testing.date": "November 2023",
      "project.itjuniors.timeline.softlaunch.title": "Soft Launch",
      "project.itjuniors.timeline.softlaunch.description": "Bereitstellung der Plattform für eine begrenzte Benutzergruppe, um Feedback zu sammeln und weitere Verbesserungen vorzunehmen.",
      'projects.viewDetails': 'Details Anzeigen',
      'workProcess.title': 'Unser Arbeitsprozess',
      'workProcess.introduction.title': 'Wie Wir Arbeiten',
            'workProcess.introduction.paragraph1': 'Bei Kodikolab glauben wir, dass ein effektiver Arbeitsprozess der Schlüssel zum Erfolg jedes Projekts ist. Unser bewährter Prozess verbindet Flexibilität mit Präzision und gewährleistet optimale Ergebnisse für unsere Kunden.',

      'workProcess.introduction.paragraph2': 'Von der ersten Beratung bis zur kontinuierlichen Optimierung ist jede Phase unseres Prozesses sorgfältig darauf ausgelegt, höchste Qualität, Effizienz und Kundenzufriedenheit zu gewährleisten.',
      'workProcess.stepsOverview.title': 'Phasen Unseres Prozesses',
      'workProcess.stepsOverview.description': 'Unser Arbeitsprozess besteht aus 8 Schlüsselphasen, die alle für den Projekterfolg entscheidend sind. Klicken Sie auf jede Phase, um mehr zu erfahren.',
      'workProcess.benefits.title': 'Vorteile Unseres Ansatzes',
      'workProcess.benefits.description': 'Unser einzigartiger Ansatz für den Arbeitsprozess bringt unseren Kunden viele Vorteile:',
      "project.itjuniors.timeline.softlaunch.date": "Dezember 2023",
      "project.itjuniors.timeline.launch.title": "Offizieller Start",
      "project.itjuniors.timeline.launch.description": "Vollständiger Start der ITJuniors-Plattform für alle Benutzer.",
      "project.itjuniors.timeline.launch.date": "März 2024",
      "project.itjuniors.cta.title": "Verwirklichen Sie Ihr Projekt mit KodikoLab",
      "project.itjuniors.cta.description": "Sind Sie daran interessiert, eine innovative Bildungsplattform oder eine andere fortschrittliche technologische Lösung zu erstellen? Unser Expertenteam bei KodikoLab ist bereit, Ihnen dabei zu helfen, Ihre Vision in die Realität umzusetzen. Kontaktieren Sie uns, um Ihr Projekt zu besprechen und die Zusammenarbeit zu beginnen.",
      "project.itjuniors.cta.button": "Kontaktieren Sie uns",
    'footer.quickLinks.title': 'Schnellzugriff',
    'footer.quickLinks.home': 'Startseite',
    'footer.quickLinks.projects': 'Projekte',
    'footer.quickLinks.about': 'Über uns',
    'footer.quickLinks.contact': 'Kontakt',
    'footer.contact.title': 'Kontaktieren Sie uns',
    'footer.contact.email': 'info@kodikolab.com',
    'footer.contact.phone': '+49 123 456789',
    'footer.contact.address': 'Technikstraße 123, 10115 Berlin',
    'footer.copyright': '© {year} Kodikolab. Alle Rechte vorbehalten.',
    'projects.pageTitle': 'Unsere Projekte',
    'projects.searchPlaceholder': 'Projekte suchen...',
    'projects.filterButton': 'Filtern',
    'projects.loading': 'Projekte werden geladen',
    'projects.statusLabel': 'Status',
    'projects.clientLabel': 'Kunde',
    'projects.fetchError': 'Fehler beim Abrufen der Projekte',
    'projects.loadError': 'Fehler beim Laden der Projekte. Beispieldaten werden angezeigt.',
    'projects.filter.all': 'Alle',
    'projects.status.completed': 'Abgeschlossen',
    'projects.status.planning': 'In Planung',
    'projects.client.techInnovators': 'Tech Innovators GmbH',
    'projects.client.globalSolutions': 'Global Solutions AG',
    'projects.client.futureSystems': 'Future Systems GmbH',
    'projects.client.digitalDynamics': 'Digital Dynamics OHG',
    'projects.client.ecoTech': 'EcoTech Solutions GmbH',
    'projects.client.smartInnovations': 'Smart Innovations AG',
    'projects.type.webDev': 'Webentwicklung',
    'projects.type.mobileApp': 'Mobile App',
    'projects.type.cloudSolution': 'Cloud-Lösung',
    'projects.type.aiIntegration': 'KI-Integration',
    'projects.type.iotPlatform': 'IoT-Plattform',
    'projects.type.blockchain': 'Blockchain-Implementierung',
    'projects.description': 'Ein hochmodernes {type}-Projekt, das die neuesten Technologien nutzt, um komplexe geschäftliche Herausforderungen zu lösen. Dieses Projekt zielt darauf ab, die Branche mit seinem einzigartigen Ansatz und fortschrittlichen Funktionen zu revolutionieren.',
    'services.pageTitle': 'Unsere Dienstleistungen',
    'services.net.title': '.NET-Entwicklung',
    'services.net.description': 'Expertenentwicklung in .NET für robuste, skalierbare Anwendungen',
    'services.react.title': 'React-Entwicklung',
    'services.react.description': 'Erstellung dynamischer und responsiver Benutzeroberflächen mit React',
    'services.angular.title': 'Angular-Entwicklung',
    'services.angular.description': 'Entwicklung leistungsstarker Einzelseitenanwendungen mit Angular',
    'services.database.title': 'Datenbankdesign',
    'services.database.description': 'Effiziente und sichere Datenbanklösungen für Ihre Datenanforderungen',
      'services.backend.title': 'Backend-Entwicklung',
      'services.expertiseDescription': 'Unser Expertenteam ist darauf spezialisiert, modernste Softwarelösungen zu liefern, die auf Ihre einzigartigen Geschäftsanforderungen zugeschnitten sind. Mit jahrelanger Erfahrung in verschiedenen Branchen bringen wir innovative Ansätze in jedes Projekt ein.',
      'services.processDescription': 'Unser bewährter Entwicklungsprozess gewährleistet hochwertige Ergebnisse und reibungslose Zusammenarbeit. Von der ersten Beratung bis zur endgültigen Bereitstellung beziehen wir Sie in jeden Schritt ein.',
      'services.process.step1': 'Beratung und Planung',
      'services.ourProcess': 'Unser Entwicklungsprozess',
      'services.process.step2': 'Design und Entwicklung',
      'services.process.step3': 'Tests und Qualitätssicherung',
      'services.process.step4': 'Bereitstellung und Support',
      'team.seoTitle': 'Unser Expertenteam - Kodikolab Softwareentwicklungsprofis',
      'team.seoDescription': 'Lernen Sie die talentierten Fachleute hinter Kodikolabs innovativen Softwarelösungen kennen. Unser vielfältiges Expertenteam ist darauf spezialisiert, außergewöhnliche Ergebnisse zu liefern.',
      'team.seoKeywords': 'Kodikolab Team, Softwareentwicklungsexperten, IT-Profis, Tech-Innovatoren',
      'team.schemaName': 'Kodikolab Entwicklungsteam',
      'team.schemaDescription': 'Das Expertenteam für Softwareentwicklung bei Kodikolab, das innovative und hochwertige Lösungen liefert',
      'services.contactUs': 'Kontaktieren Sie uns',
      'services.viewPortfolio': 'Unser Portfolio ansehen',
      'services.faq': 'Häufig gestellte Fragen',
      'services.faq1.question': 'Welche Arten von Projekten bearbeiten Sie?',
      'services.faq1.answer': 'Wir bearbeiten eine breite Palette von Projekten, von kleinen Unternehmenswebsites bis hin zu komplexen Unternehmenssoftwarelösungen. Unsere Expertise umfasst Webentwicklung, mobile Apps, kundenspezifische Software und mehr.',
      'services.faq2.question': 'Wie lange dauert ein typisches Projekt?',
      'services.faq2.answer': 'Die Projektdauer variiert je nach Komplexität und Umfang. Eine einfache Website kann 4-6 Wochen dauern, während eine komplexe Softwarelösung mehrere Monate in Anspruch nehmen kann. Wir stellen Ihnen während unserer ersten Beratung einen detaillierten Zeitplan zur Verfügung.',
      'services.faq3.question': 'Bieten Sie nach Abschluss des Projekts laufenden Support an?',
      'services.faq3.answer': 'Ja, wir bieten laufende Support- und Wartungsdienste an, um sicherzustellen, dass Ihre Software weiterhin optimal funktioniert. Wir können ein Supportpaket auf Ihre spezifischen Bedürfnisse zuschneiden.',
    'services.backend.description': 'Solide Backend-Architekturen zur Unterstützung Ihrer Anwendungen',
    'services.wordpress.title': 'WordPress-Entwicklung',
    'services.wordpress.description': 'Maßgeschneiderte WordPress-Themes und Plugins für Ihre individuellen Bedürfnisse',
    'services.custom.title': 'Individuelle Lösungen',
    'services.custom.description': 'Maßgeschneiderte Softwarelösungen, um Ihre einzigartigen Anforderungen zu erfüllen',
      'services.calculator.title': 'Projektkostenrechner',
      'services.ourExpertise': 'Unsere Expertise',
      'services.individualApproach': 'Individueller Ansatz',
      'services.approachDescription': 'Bei Kodikolab glauben wir, dass jedes Projekt einzigartig ist. Unsere Lösungen sind auf die spezifischen Bedürfnisse Ihres Unternehmens zugeschnitten.',
      'services.benefits.tailored': 'Maßgeschneiderte Lösungen',
      'services.benefits.expertise': 'Langjährige Branchenerfahrung',
      'services.benefits.quality': 'Höchste Codequalität',
      'services.benefits.support': '24/7 Support',
      'services.readyToStart': 'Bereit zu starten?',
      'services.ctaDescription': 'Kontaktieren Sie uns noch heute, um Ihr Projekt zu besprechen und innovative Lösungen für Ihr Unternehmen zu entwickeln.',
      'services.showDetails': 'Details anzeigen',
      'services.hideDetails': 'Details ausblenden',
      'project.technologies.title': 'Technologien',
      'project.timeline.title': 'Projektzeitplan',
    'services.calculator.selectDesign': 'Design-Anforderung auswählen',
    'services.calculator.basic': 'Basis',
    'services.calculator.custom': 'Individuell',
    'services.calculator.premium': 'Premium',
    'services.calculator.calculateButton': 'Kostenvoranschlag berechnen',
    'services.calculator.estimatedCost': 'Geschätzte Kosten: {cost} €',
    'services.calculator.contactButton': 'Kontaktieren Sie uns für Details',
    'about.pageTitle': 'Über KodikoLab',
      'about.story.title': 'Unsere Geschichte',
      'project.itjuniors.name': 'ITJuniors',
      'project.itjuniors.description': 'Eine innovative Bildungsplattform für Kinder im Alter von 7-11 Jahren zum Erlernen des Programmierens durch Minecraft Education Edition.',
      'project.stages.concept': 'Konzept',
      'project.stages.concept.description': 'Entwicklung der Hauptprojektannahmen und Analyse der Bildungsbedürfnisse junger Lernender.',
      'project.stages.planning': 'Planung',
      'project.stages.planning.description': 'Erstellung eines detaillierten Projektplans, Lehrplans und der Lernziele.',
      'project.stages.design': 'UX/UI-Design',
      'project.stages.design.description': 'Gestaltung kinderfreundlicher Schnittstellen und Minecraft-basierter Lernumgebungen.',
      'project.stages.development': 'Entwicklung',
      'project.stages.development.description': 'Implementierung der Plattform und Integration mit Minecraft Education Edition.',
      'project.stages.testing': 'Testen',
      'project.stages.testing.description': 'Durchführung gründlicher Tests mit Fokusgruppen von Kindern und Pädagogen.',
      'project.stages.launch': 'Start',
      'project.stages.launch.description': 'Offizieller Start der ITJuniors-Plattform.',
      'project.stages.planned.from': 'Geplant ab {date}',
      'project.stages.planned.for': 'Geplant für {date}',
      'project.itjuniors.seo.title': 'ITJuniors: Bildungsplattform für zukünftige Programmierer | Kodikolab',
      'project.itjuniors.seo.description': 'Entdecken Sie ITJuniors, eine innovative E-Learning-Plattform von Kodikolab, die angehende Programmierer auf ihrem Weg zum Erfolg in der IT-Branche unterstützt.',
      'project.itjuniors.seo.keywords': 'ITJuniors, Bildungsplattform, Programmierung, E-Learning, Kodikolab, IT-Branche',

      'project.smartcity.seo.title': 'SmartCity App: Umfassende Lösung für Stadtmanagement | Kodikolab',
      'project.smartcity.seo.description': 'Erkunden Sie unsere SmartCity App, eine umfassende mobile Anwendung für intelligentes Stadtmanagement, die IoT-Geräte und Echtzeitdatenanalyse integriert.',
      'project.smartcity.seo.keywords': 'SmartCity App, Stadtmanagement, IoT, Datenanalyse, Kodikolab, Smart City',
      'project.keyFeatures': 'Hauptfunktionen',
      'project.features.minecraft': 'Lernen durch Minecraft Education Edition',
      'project.features.ageAppropriate': 'Altersgerechte Inhalte für 7-11 Jährige',
      'project.features.interactiveLessons': 'Interaktive Coding-Lektionen in der Minecraft-Welt',
      'project.features.progressTracking': 'Gamifiziertes Fortschrittsverfolgungssystem',
      'project.technologies': 'Technologien',
      'project.progress': 'Projektfortschritt',
      'services.benefits.innovation': 'Bahnbrechende Innovation',
      'services.benefits.costEffective': 'Kosteneffiziente Lösungen',
      'project.faq': 'FAQ',
      'project.faq.launch': 'Wann wird ITJuniors verfügbar sein?',
      'project.faq.launch.answer': 'Wir planen, ITJuniors im dritten Quartal 2024 zu starten.',
      'project.faq.age': 'Ist ITJuniors für Kinder außerhalb der Altersgruppe 7-11 Jahre geeignet?',
      'project.faq.age.answer': 'Die Inhalte sind speziell für Kinder im Alter von 7-11 Jahren konzipiert, aber wir erwägen, unser Angebot in Zukunft auf verschiedene Altersgruppen auszuweiten.',
      'loading': 'Laden...',
      'workProcess.seoTitle': 'Unser Arbeitsprozess - Softwareentwicklungsmethodik von Kodikolab',
      'workProcess.seoDescription': 'Entdecken Sie den effizienten und effektiven Softwareentwicklungsprozess von Kodikolab, von der Beratung bis zur kontinuierlichen Verbesserung.',
      'workProcess.seoKeywords': 'Arbeitsprozess, Softwareentwicklungsmethodik, Kodikolab, agile Entwicklung',
      'workProcess.schemaName': 'Kodikolab Arbeitsprozess',
      'workProcess.schemaDescription': 'Detaillierter Überblick über den Softwareentwicklungsprozess und die Methodik von Kodikolab',
    'about.story.paragraph1': 'KodikoLab wurde im Oktober 2024 aus der Leidenschaft für die Erstellung außergewöhnlicher digitaler Lösungen gegründet. Unser Team aus erfahrenen Entwicklern und kreativen Köpfen kam mit einer gemeinsamen Vision zusammen: Ideen in leistungsstarke, effiziente und innovative Softwareanwendungen zu verwandeln.',
    'about.story.paragraph2': 'Seit unserer Gründung haben wir es uns zur Aufgabe gemacht, den Standard der Softwareentwicklung zu erhöhen. Wir glauben, dass großartiger Code nicht nur funktional sein sollte - es geht darum, elegante Lösungen zu schaffen, die das Geschäftswachstum und die Benutzerzufriedenheit fördern.',
    'about.mission.title': 'Unsere Mission',
    'about.mission.paragraph1': 'Bei KodikoLab ist es unsere Mission, Unternehmen durch transformative digitale Lösungen zu stärken. Wir streben danach, Software zu entwickeln, die die Erwartungen unserer Kunden nicht nur erfüllt, sondern übertrifft, und dabei Innovation fördert und Erfolg im digitalen Zeitalter vorantreibt.',
    'about.mission.paragraph2': 'Wir sind bestrebt, in jeder Codezeile, jeder Benutzeroberfläche und jeder digitalen Erfahrung, die wir schaffen, Exzellenz zu liefern. Unser Ziel ist es, an der Spitze des technologischen Fortschritts zu stehen und kontinuierlich die Grenzen des in der Softwareentwicklung Möglichen zu erweitern.',
    'about.values.title': 'Unsere Werte',
    'about.values.description': 'Im Herzen von KodikoLab stehen die Werte, die unsere Arbeit leiten und unsere Kultur prägen. Diese Prinzipien sind das Fundament unseres Erfolgs und die treibende Kraft hinter unserem Engagement für Exzellenz.',
    'about.values.codeExcellence.title': 'Code-Exzellenz',
    'about.values.codeExcellence.description': 'Wir verpflichten uns, sauberen, effizienten und wartbaren Code zu schreiben, der die Zeit überdauert.',
    'about.values.innovation.title': 'Innovation',
    'about.values.innovation.description': 'Wir erforschen ständig neue Technologien und Methoden, um bahnbrechende Lösungen zu liefern.',
    'about.values.clientPartnership.title': 'Kundenpartnerschaft',
    'about.values.clientPartnership.description': 'Wir glauben an den Aufbau starker, langfristiger Beziehungen zu unseren Kunden und werden zu wahren Partnern in ihrem Erfolg.',
    'about.values.continuousGrowth.title': 'Kontinuierliches Wachstum',
    'about.values.continuousGrowth.description': 'Wir engagieren uns für kontinuierliches Lernen und Verbesserung und bleiben in der schnelllebigen Welt der Technologie stets an der Spitze.',
    'about.team.title': 'Unser Team',
    'about.team.description1': 'KodikoLab wird von einem Team leidenschaftlicher und erfahrener Fachleute angetrieben. Mit jahrelanger kollektiver Erfahrung in verschiedenen Bereichen der Softwareentwicklung bringen unsere Experten eine Fülle von Wissen und Kreativität in jedes Projekt ein.',
    'about.team.description2': 'Von erfahrenen .NET-Entwicklern bis hin zu React- und Angular-Spezialisten, von Datenbankarchitekten bis hin zu UX/UI-Designern ist unser Team in der Lage, Projekte jeder Größenordnung und Komplexität zu bewältigen. Wir sind stolz auf unseren kollaborativen Ansatz, bei dem wir unsere individuellen Stärken kombinieren, um ganzheitliche Lösungen zu liefern, die echte Ergebnisse erzielen.',
    'about.expertise.title': 'Unsere Expertise',
    'about.expertise.introduction': 'Bei KodikoLab sind wir auf ein breites Spektrum von Technologien und Dienstleistungen spezialisiert, darunter:',
    'about.expertise.list': '.NET-Entwicklung, React- und Angular-Frontend-Lösungen, Datenbankdesign und -optimierung, Individuelle Softwareentwicklung, Web- und Mobile-App-Entwicklung, Cloud-Lösungen und -Integration',
    'about.expertise.conclusion': 'Unsere Vielseitigkeit ermöglicht es uns, auf vielfältige Kundenbedürfnisse einzugehen, von Startups, die nach MVP-Entwicklung suchen, bis hin zu großen Unternehmen, die komplexe, skalierbare Lösungen benötigen.',
    'about.commitment.title': 'Unser Engagement',
    'about.commitment.paragraph1': 'Bei KodikoLab sind wir mehr als nur ein Softwareentwicklungsunternehmen - wir sind Ihr Technologiepartner. Wir setzen uns dafür ein, Ihre einzigartigen Herausforderungen und Ziele zu verstehen und Lösungen zu liefern, die nicht nur Ihre aktuellen Bedürfnisse erfüllen, sondern Sie auch für zukünftigen Erfolg positionieren.',
    'about.commitment.paragraph2': 'Ob Sie ein kleines Unternehmen sind, das seine digitale Präsenz aufbauen möchte, oder ein großes Unternehmen, das seine Abläufe durch individuelle Software optimieren will - KodikoLab ist hier, um Ihre Vision Wirklichkeit werden zu lassen. Begleiten Sie uns auf dieser spannenden Reise der digitalen Transformation und lassen Sie uns gemeinsam etwas Außergewöhnliches schaffen.',
    'contact.pageTitle': 'Kontaktieren Sie uns',
    'contact.infoTitle': 'Wir würden gerne von Ihnen hören',
    'contact.infoDescription': 'Bereit für Ihr nächstes Projekt oder haben Sie Fragen zu unseren Dienstleistungen? Kontaktieren Sie uns und lassen Sie uns gemeinsam etwas Großartiges schaffen!',
    'contact.email': 'info@kodikolab.com',
    'contact.phone': '+49 555 123 4567',
    'contact.address': 'Technikstraße 123, 10115 Berlin',
    'contact.form.name': 'Name',
    'contact.form.email': 'E-Mail',
    'contact.form.phone': 'Telefonnummer',
    'contact.form.subject': 'Betreff',
    'contact.form.message': 'Nachricht',
    'contact.form.send': 'Nachricht senden',
    'contact.form.sending': 'Wird gesendet...',
    'contact.form.successMessage': 'Vielen Dank für Ihre Nachricht! Wir werden uns bald bei Ihnen melden.',
      'contact.form.errorMessage': 'Hoppla! Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.',
      'nav.team': 'Team',
      'nav.workProcess': 'Arbeitsprozess',
      'team.pageTitle': 'Unser Team',
      'team.pageDescription': 'Lernen Sie das Expertenteam hinter Kodikolabs innovativen Lösungen kennen.',
      'team.pageKeywords': 'Team, Experten, Entwickler, Designer, Kodikolab',
      'workProcess.consultation.title': 'Beratung',
      'workProcess.consultation.shortDescription': 'Verstehen Ihrer Bedürfnisse',
      'workProcess.planning.title': 'Planung',
      'project.features.title': 'Hauptfunktionen',
      'workProcess.planning.shortDescription': 'Erstellung der Roadmap',
      'workProcess.development.title': 'Entwicklung',
      'workProcess.testing.title': 'Testen',
      'workProcess.testing.shortDescription': 'Qualitätssicherung',
      'workProcess.deployment.shortDescription': 'Live-Schaltung',
      'workProcess.maintenance.title': 'Wartung',
      'workProcess.maintenance.shortDescription': 'Laufende Unterstützung',
      'services.customDevelopment.title': 'Individuelle Softwareentwicklung',
      'services.customDevelopment.description': 'Maßgeschneiderte Softwarelösungen für Ihre einzigartigen Geschäftsanforderungen',
      'services.customDevelopment.details': 'Wir entwickeln maßgeschneiderte Softwareanwendungen, die perfekt auf Ihre Geschäftsprozesse und -ziele abgestimmt sind.',
      'services.frontendDevelopment.title': 'Frontend-Entwicklung',
      'services.frontendDevelopment.description': 'Erstellung ansprechender und reaktionsfähiger Benutzeroberflächen',
      'services.frontendDevelopment.details': 'Unsere Frontend-Experten gestalten intuitive und optisch ansprechende Schnittstellen, die das Benutzererlebnis verbessern.',
      'services.backendDevelopment.title': 'Backend-Entwicklung',
      'services.backendDevelopment.description': 'Aufbau robuster und skalierbarer serverseitiger Anwendungen',
      'services.backendDevelopment.details': 'Wir entwickeln leistungsstarke Backend-Systeme, die eine reibungslose Datenverarbeitung und Anwendungsleistung gewährleisten.',
      'services.mobileDevelopment.title': 'Mobile App-Entwicklung',
      'services.mobileDevelopment.description': 'Entwicklung nativer und plattformübergreifender mobiler Anwendungen',
      'services.mobileDevelopment.details': 'Von iOS bis Android erstellen wir mobile Apps, die auf allen Geräten ein außergewöhnliches Benutzererlebnis bieten.',
      'services.databaseDesign.title': 'Datenbankdesign',
      'services.databaseDesign.description': 'Gestaltung effizienter und sicherer Datenbanklösungen',
      'services.databaseDesign.details': 'Wir entwerfen Datenbanksysteme, die Datenintegrität, Sicherheit und optimale Leistung gewährleisten.',
      'services.cloudServices.title': 'Cloud-Dienste',
      'services.cloudServices.description': 'Nutzung von Cloud-Technologien für skalierbare Lösungen',
      'services.cloudServices.details': 'Wir helfen Unternehmen, die Leistungsfähigkeit des Cloud Computing für mehr Flexibilität und Kosteneffizienz zu nutzen.',
      'services.containerization.title': 'Containerisierung',
      'services.containerization.description': 'Implementierung containerbasierter Lösungen für verbesserte Bereitstellung',
      'services.containerization.details': 'Wir verwenden Docker und Kubernetes, um die Anwendungsbereitstellung und -skalierung zu optimieren.',
      'services.security.title': 'Cybersicherheit',
      'services.security.description': 'Implementierung robuster Sicherheitsmaßnahmen zum Schutz Ihrer digitalen Ressourcen',
      'services.security.details': 'Unsere Sicherheitsexperten sorgen dafür, dass Ihre Anwendungen und Daten vor Cyber-Bedrohungen geschützt sind.',
      'services.maintenance.title': 'Wartung und Support',
      'services.maintenance.description': 'Bereitstellung laufender Unterstützung und Aktualisierungen für Ihre Software',
      'services.maintenance.details': 'Wir bieten umfassende Wartungsdienste, um Ihre Software reibungslos und auf dem neuesten Stand zu halten.',
      'services.performanceOptimization.title': 'Leistungsoptimierung',
      'services.performanceOptimization.description': 'Verbesserung der Softwareleistung für höhere Effizienz',
      'services.performanceOptimization.details': 'Wir optimieren Ihre Anwendungen, um optimale Geschwindigkeit und Ressourcennutzung zu erreichen.',
      'services.devops.title': 'DevOps-Dienste',
      'services.devops.description': 'Implementierung von DevOps-Praktiken für verbesserte Entwicklungsabläufe',
      'services.devops.details': 'Wir integrieren DevOps-Methoden, um Entwicklungs-, Test- und Bereitstellungsprozesse zu optimieren.',
      'services.consultancy.title': 'IT-Beratung',
      'services.consultancy.description': 'Bereitstellung von Expertenberatung zu Technologiestrategien',
      'services.consultancy.details': 'Unsere Berater helfen Ihnen, fundierte Entscheidungen über Ihre IT-Infrastruktur und Softwareinvestitionen zu treffen.',
      'services.analytics.title': 'Datenanalyse',
      'services.analytics.description': 'Umwandlung von Daten in umsetzbare Erkenntnisse',
      'services.analytics.details': 'Wir implementieren Datenanalyselösungen, um Ihnen zu helfen, datengestützte Entscheidungen zu treffen und Wettbewerbsvorteile zu erzielen.',
      'services.calculator.apiDevelopment': 'API-Entwicklung',
      'services.calculator.react': 'React',
      'services.calculator.angular': 'Angular',
      'services.calculator.vue': 'Vue.js',
      'services.calculator.native': 'Native',
      'services.calculator.flutter': 'Flutter',
      'services.calculator.mysql': 'MySQL',
      'services.calculator.postgresql': 'PostgreSQL',
      'services.calculator.mongodb': 'MongoDB',
      'services.calculator.redis': 'Redis',
      'services.calculator.elasticsearch': 'Elasticsearch',
      'services.calculator.selfHosted': 'Selbst gehostet',
      'services.calculator.kodikolabHosting': 'Kodikolab-Hosting',
      'services.calculator.aws': 'Amazon Web Services (AWS)',
      'services.calculator.gcp': 'Google Cloud Platform (GCP)',
      'services.calculator.azure': 'Microsoft Azure',
      'services.calculator.security': 'Sicherheit',
      'services.calculator.testing': 'Testen',
      'services.calculator.optimization': 'Optimierung',
      'services.calculator.monitoring': 'Überwachung',
      'services.calculator.devops': 'DevOps',
      'services.calculator.analytics': 'Analytik',
      'services.calculator.consultancy': 'Beratung',
      'services.calculator.additionalServices': 'Zusätzliche Dienste',
      'services.calculator.developmentCost': 'Entwicklungskosten: {cost} €',
      'services.calculator.deploymentCost': 'Bereitstellungskosten: {cost} €',
      'services.calculator.additionalServicesCost': 'Kosten für zusätzliche Dienste: {cost} €',
      'services.calculator.totalCost': 'Geschätzte Gesamtkosten: {cost} €',
      'workProcess.benefits.list': 'Transparenz und Vorhersehbarkeit, Schnellere Wertschöpfung, Flexibilität bei Anpassungen, Hohe Qualität des Endprodukts, Kosten- und Zeitoptimierung, Kontinuierliche Verbesserung',
      'workProcess.consultation.details': 'In der Beratungsphase konzentrieren wir uns darauf, Ihre Geschäftsziele, Projektanforderungen und Erwartungen gründlich zu verstehen. Unsere Experten analysieren Ihre Bedürfnisse und schlagen die besten technologischen Lösungen vor.',
      'workProcess.consultation.benefits': 'Präzises Verständnis der Bedürfnisse, Maßgeschneiderte Lösungen, Realistische Erwartungen',
      'notFound.title': 'Seite nicht gefunden',
      'notFound.message': 'Entschuldigung, aber die gesuchte Seite existiert nicht.',
      'notFound.linkText': 'Zurück zur Startseite',
      'home.seoTitle': 'Kodikolab - Wegweisende Softwarelösungen für das digitale Zeitalter',
      'home.seoDescription': 'Kodikolab bietet modernste Softwareentwicklungsdienste, einschließlich Web- und Mobile-Anwendungen, Cloud-Lösungen und Automatisierung.',
      'home.seoKeywords': 'Softwareentwicklung, Webanwendungen, mobile Apps, Cloud-Lösungen, Automatisierung, Kodikolab',
      'home.schemaDescription': 'Kodikolab - Innovative Softwarelösungen für das digitale Zeitalter',
      'projects.seoTitle': 'Unsere Projekte - Kodikolab Softwarelösungen',
      'projects.seoDescription': 'Entdecken Sie Kodikolabs Portfolio innovativer Softwareprojekte, die unsere Expertise in Web-, Mobile- und Cloud-Entwicklung präsentieren.',
      'projects.seoKeywords': 'Softwareprojekte, Webentwicklung, mobile Apps, Cloud-Lösungen, Kodikolab Portfolio',
      'projects.schemaName': 'Kodikolab Projekte',
      'projects.schemaDescription': 'Eine Sammlung innovativer Softwareprojekte, entwickelt von Kodikolab',
      'about.seoTitle': 'Über Kodikolab - Unsere Geschichte, Mission und Werte',
      'about.seoDescription': 'Erfahren Sie mehr über Kodikolabs Weg, unsere Mission zur Bereitstellung innovativer Softwarelösungen und die Werte, die unseren Erfolg vorantreiben.',
      'about.seoKeywords': 'Kodikolab, Softwareunternehmen, unsere Geschichte, Mission, Werte, Team',
      'about.schemaName': 'Über Kodikolab',
      'about.schemaDescription': 'Informationen über Kodikolab, unsere Mission, Werte und Team',
      'contact.seoTitle': 'Kontakt Kodikolab - Kontaktieren Sie uns für Softwarelösungen',
      'contact.seoDescription': 'Kontaktieren Sie Kodikolab für innovative Softwarelösungen. Nehmen Sie Kontakt auf für Beratungen, Angebote oder um Ihre Projektanforderungen zu besprechen.',
      'contact.seoKeywords': 'Kontakt Kodikolab, Softwareentwicklungsanfrage, Projektberatung',
      'contact.schemaName': 'Kontakt Kodikolab',
      'contact.schemaDescription': 'Kontaktinformationen und Formular zur Kontaktaufnahme mit Kodikolab',
      'services.seoTitle': 'Unsere Dienstleistungen - Kodikolab Softwareentwicklungslösungen',
      'services.seoDescription': 'Entdecken Sie Kodikolabs umfassende Softwareentwicklungsdienste, einschließlich Web- und Mobile-App-Entwicklung, Cloud-Lösungen und mehr.',
      'services.seoKeywords': 'Softwareentwicklungsdienste, Webentwicklung, mobile Apps, Cloud-Lösungen, Kodikolab',
      'services.schemaName': 'Kodikolab Dienstleistungen',
      'services.schemaDescription': 'Übersicht der von Kodikolab angebotenen Softwareentwicklungsdienste',
      'workProcess.planning.details': 'Wir erstellen einen detaillierten Projektplan, einschließlich Zeitplänen, Meilensteinen und Ressourcenzuweisung. Diese Phase bildet die Grundlage für eine erfolgreiche Projektdurchführung.',
      'workProcess.planning.benefits': 'Klare Projektvision, Effiziente Ressourcenzuweisung, Risikominimierung',
      'workProcess.development.shortDescription': 'Ideen zum Leben erwecken',
      'workProcess.development.details': 'Unser Expertenteam setzt Pläne in die Realität um und schreibt sauberen, effizienten Code. Wir folgen bewährten Praktiken und Branchenstandards, um hochwertige, skalierbare Lösungen zu gewährleisten.',
      'workProcess.development.benefits': 'Hochwertiger Code, Skalierbare Lösungen, Effizienter Entwicklungsprozess',
      'workProcess.testing.details': 'Wir führen gründliche Tests durch, um sicherzustellen, dass Ihre Lösung den höchsten Qualitätsstandards entspricht. Dies umfasst Unit-Tests, Integrationstests und Benutzerakzeptanztests.',
      'workProcess.testing.benefits': 'Fehlerfreie Software, Verbesserte Benutzererfahrung, Reduzierte Probleme nach dem Start',
      'workProcess.deployment.title': 'Bereitstellung',
      'workProcess.deployment.details': 'Wir starten Ihre Lösung sorgfältig und gewährleisten einen reibungslosen Übergang in die Produktion. Unser Bereitstellungsprozess ist darauf ausgelegt, Ausfallzeiten zu minimieren und die Zuverlässigkeit zu maximieren.',
      'workProcess.deployment.benefits': 'Reibungsloser Übergang in die Produktion, Minimale Störungen, Zuverlässiger Start',
      'workProcess.maintenance.details': 'Wir bieten kontinuierliche Unterstützung und Updates, um den reibungslosen Betrieb Ihrer Lösung zu gewährleisten. Unsere Wartungsdienste umfassen Fehlerbehebungen, Sicherheitsupdates und Leistungsüberwachung.',
      'workProcess.maintenance.benefits': 'Konstante Leistung, Aktuelle Sicherheit, Seelenfrieden',
      'workProcess.optimization.title': 'Optimierung',
      'workProcess.optimization.shortDescription': 'Kontinuierliche Verbesserung',
      'workProcess.optimization.details': 'Nach der Bereitstellung überwachen wir kontinuierlich die Leistung und sammeln Daten, um Bereiche für Optimierungen zu identifizieren. Wir implementieren Verbesserungen, die die Effizienz, Skalierbarkeit und Benutzererfahrung verbessern.',
      'workProcess.optimization.benefits': 'Gesteigerte Effizienz, Bessere Skalierbarkeit, Kostenoptimierung',
      'workProcess.feedback.title': 'Feedback und Iteration',
      'workProcess.feedback.shortDescription': 'Kontinuierliche Verfeinerung',
      'workProcess.feedback.details': 'Wir sammeln regelmäßig Feedback von Benutzern und Kunden. Diese wertvollen Informationen werden für die kontinuierliche Produktverbesserung und die Planung zukünftiger Funktionen verwendet.',
      'workProcess.feedback.benefits': 'Benutzerzentriertes Produkt, Kontinuierliche Verbesserungen, Langfristiger Wert'
  }
};