import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FaUsers, FaLightbulb, FaHandshake, FaRocket } from 'react-icons/fa';
import { fadeInUp } from './GlobalStyles';


const Section = styled.section`
  background: linear-gradient(180deg, #2a5298, #1e3c72);
  color: #ffffff;
  padding: 80px 0 80px;
  text-align: center;
  position: relative;
  overflow: hidden;
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 3rem;
  animation: ${fadeInUp} 1s ease-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: #ffffff;
`;

const FeatureList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 0;
  list-style-type: none;
  max-width: 1200px;
  margin: 0 auto;
`;

const FeatureItem = styled.li`
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  padding: 2rem;
  transition: all 0.4s ease;
  animation: ${fadeInUp} 1s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.delay}s;
  &:hover {
    transform: translateY(-10px) scale(1.05);
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: #7efff5;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  opacity: 0.95;
  color: #f0f0f0;
  line-height: 1.6;
`;

const WhyKodikolab = () => {
  const intl = useIntl();

  const features = [
    { icon: <FaUsers />, titleId: 'why.expertTeam.title', descriptionId: 'why.expertTeam.description' },
    { icon: <FaLightbulb />, titleId: 'why.innovativeSolutions.title', descriptionId: 'why.innovativeSolutions.description' },
    { icon: <FaHandshake />, titleId: 'why.clientFocused.title', descriptionId: 'why.clientFocused.description' },
    { icon: <FaRocket />, titleId: 'why.leadingTechnology.title', descriptionId: 'why.leadingTechnology.description' },
  ];

  return (
    <Section>
      <SectionTitle>{intl.formatMessage({ id: 'why.sectionTitle' })}</SectionTitle>
      <FeatureList>
        {features.map((feature, index) => (
          <FeatureItem key={feature.titleId} delay={0.2 * (index + 1)}>
            <FeatureIcon>{feature.icon}</FeatureIcon>
            <FeatureTitle>{intl.formatMessage({ id: feature.titleId })}</FeatureTitle>
            <FeatureDescription>{intl.formatMessage({ id: feature.descriptionId })}</FeatureDescription>
          </FeatureItem>
        ))}
      </FeatureList>
    </Section>
  );
};

export default WhyKodikolab;